<template>
    <div class="container home-padding">
    <div class="row" v-if="products.length !=0">

        <!-- <div class="col-lg-3 col-md-5 col-sm-12 col-12 side-bar-border">
            <side-bar-section> </side-bar-section>

        </div> -->
       
        <div class="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12">
            <div class="row">
            <div class="col-lg-10 offset-lg-5 col-md-12 col-sm-12 col-12">
                <label class="deliverto-order">
                    <img src="/images/deliver-address-icon.png"> {{ $t('deliver-to') }}
                </label>
                <select name="address" class="address select-address">
                    <option v-for="address in addresses" v-bind:value="address.id">{{ address.city }} , {{ address.street }} , {{ address.fullAddress }}</option>
                </select>
                <div v-if="addresses.length==0" id="responsecart">{{ $t('please-insert-an-address') }}</div>
            </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12 container  side-bar-gray">
            <div class="my-orders" v-for="(product,index) in products">
                <div class="my-orders">
                <div class="order">
                    <div class="order-status">
                       
                        <div class="row ">
            <div class="my-orders">
                <div class="container order-address "  >
                        <!--  order row-->
                        <div class="row "> 
                            <div class="col-lg-3 col-md-3 col-3">
                                <img class="order-product-img" v-bind:src="product.prodImg">
                            </div>

                            <div class="col-lg-9 col-md-9 col-9">
                                <div class="row">
                                    <div class="col-10">
                                        <h6 class="prod-name order-name-prod">{{product.prdName}}</h6>
                                    </div>
                                    <div class="col-2">
                                        <img v-on:click="removeProduct(index)" src="/images/delete-item.png">
                                    </div>
                                </div>
                               <h6 class="prod-count-order">{{product.prdCount}} Pcs</h6>
                               <div class="row">
                                    <div class="col-lg-6 col-md-6 col-5">
                                        <h6 class="order-price">{{product.prdtotal}} ILS</h6>
                                      

                                    </div>

                                <div class="col-lg-6 col-md-6 col-7">
                                    <div class="row ltr-element" >
                                    <div class="col-lg-1 col-md-3 col-sm-3 col-3  quant-btn quant-btn-border" @click="minquant(index)">
                                 <span class="quant-mark"> - </span>  
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6 quant-btn-input quant-btn-div">
                                <input type="text" class="form-control col-lg-5 quant-btn-input"  v-model="product.prdCount" />
                            </div>
                            <div class="col-lg-1 col-md-3 col-sm-3 col-3 quant-btn quant-btn-border-right " @click=addquant(index)>
                                <span class="quant-mark"> + </span>  
                            </div>
                        </div>
                                </div>
                            </div>
                            </div>

                            
                         </div>
                              </div>
                        
                     </div>
                </div>
            </div>
        </div>
    </div>
</div>

        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 container "  >
            <div class="my-orders order-total" >
                    <div class="my-orders" >
                         <div class="order">
                                <div class="order-status">
                                    <div class="row ">
                                        <div class="my-orders" >
                                            <div class="container order-address "  >
                                                <div class="row secondary-text-order">
                                                    <div class="col-6">
                                                        {{ products.length }} {{ $t('item') }}
                                                    </div>
                                                    <div class="col-6">
                                                        {{ orderTotal }} ILS
                                                    </div>
                                                </div>

                                                <div class="row  order-det-pad secondary-text-order">
                                                    <div class="col-6">
                                                       {{ $t('discount') }}    ({{ discount_percent }} %)
                                                    </div>

                                                    <div class="col-6">
                                                        {{ Discount }} ILS
                                                    </div>
                                                </div>

                                                <div class="row  order-det-pad ">
                                                    <div class="col-6">
                                                      <h6>{{ $t('Total') }} </h6>
                                                    </div>
                                                    <div class="col-6">
                                                        <h6> {{ DiscountedTotal }} ILS</h6>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                           
                                        </div>
                                        <div class="order-det-pad checkout">
                                                    <button v-if="products && addresses" class="primary-btn-no-border   border-radius-btn no-decoration" v-on:click="checkOut()">        
                                                        Checkout
                                                    </button>

                                                  
                                                </div>
                                </div>
                                
                        </div>
                        
                     </div>
                     
                  </div>
                  
                    </div>
                     </div>
                  </div> 
               </div>

             
            </div>
            <div  v-else>
                <div class="row">
                    <div class="col-3  cart-empty"> </div>
                    <div class="col-6  cart-empty"> 
                        
                        <img class="empty-cart-img" src="images/shopping-cart-2029.png">   
                        <h6>{{ $t('your-shipping-cart-is-empty') }}</h6>
                        <router-link class="nav-link main-nav-item" :to="{ name: 'Products' ,params:{locale:this.$i18n.locale} }" role="button"> <h6>{{ $t('view-products') }}</h6> </router-link>   
                    </div>
                    <div class="col-3  cart-empty"> </div>   
                </div>
             
          </div>
         </div>
                        <!-- <div class="row order-status"> 
                            <div class="col-lg-4 col-md-4 col-4">
                                <img class="order-product-img" src="/images/prod.png">
                            </div>

                            <div class="col-lg-4 col-md-3 col-3">
                               <h6 class="prod-name">OBH Combi</h6>
                               <span >75ml</span>
                               <h6 class="prod-count">10 Pcs</h6>
                            </div>

                            <div class="col-lg-4 col-md-3 col-3">
                               <h6 >2000</h6>
                               
                               
                            </div>
                        </div> -->


        
					<PopUp 
					v-if="popupTriggers.buttonTrigger"
					:TogglePopup="()=>TogglePopup('buttonTrigger')">
						<h4>تمت الإضافة الطلب بنجاح</h4>
				</PopUp>
					               

                       
              
    

</template>


<script>

import SideBarSection from '../../components/Profile/SideBar.vue';
import axios from 'axios';
import PopUp from '../../components/Pages/PopUp.vue';
import {ref} from 'vue';

export default {
name: "ShippingCart",
components: { 
    SideBarSection ,    
    PopUp
   
 }, 

 data() {
    return {
        products:[],
        orderTotal:0,
        Discount:0,
        DiscountedTotal:0,
        addresses:[],
        products_ids:[],
        products_quants:[],
        discount_percent:0,
    };
  },

mounted(){
window.scrollTo(0, 0);
this.discount_percent=localStorage.getItem('discount');

this.getProducts();
this.getAddresses();
},


setup(){
    const popupTriggers=ref({
			buttonTrigger:false,	
			});
			
			const TogglePopup=(trigger) =>{
			popupTriggers.value[trigger] = !popupTriggers.value
			[trigger]
			}


			
		return {PopUp,
				popupTriggers,
				TogglePopup,
			//	submit_shippingbox
				}
},


methods:{


 getProducts()
		{	
          this.products= JSON.parse(localStorage.getItem("products"));  
          console.log("products"+this.products); 
          if(this.products !=[])
          this.products.forEach(element =>{
            this.orderTotal+=element.prdtotal;
          })
        
          this.Discount= (this.orderTotal*this.discount_percent)/100;
          this.DiscountedTotal=this.orderTotal-this.Discount;
        }
       ,


addquant(i)
        {
            this.products[i].prdCount++; 
          
            this.products[i].prdtotal+=this.products[i].prdPrice;
            this.orderTotal+=this.products[i].prdPrice;
           

            this.Discount= this.orderTotal*this.discount_percent/100;
             this.DiscountedTotal=this.orderTotal-this.Discount;
             this.saveProducts();
            
        }
,
        minquant(i)
        {

            if(this.products[i].prdCount >1)
            {
                this.products[i].prdCount--; 
                this.products[i].prdtotal-=this.products[i].prdPrice;
                this.orderTotal-=this.products[i].prdPrice;
               
                this.Discount= this.orderTotal*this.discount_percent/100;
                this.DiscountedTotal=this.orderTotal-this.Discount;
                this.saveProducts();
            }
           
        },

        removeProduct(i){
                this.orderTotal-=this.products[i].prdtotal;
               
                this.Discount= this.orderTotal*this.discount_percent/100;
                this.DiscountedTotal=this.orderTotal-this.Discount;
                this.products.splice(i, 1)
                this.saveProducts();
                // location.reload(); 
                localStorage.setItem('prdNum',this.products.length)
                this.$store.commit('decrement'); 

        }, 

        checkOut(){
            console.log('aaa')
            console.log(this.addresses)
            if(this.addresses.length==0){    
            this.$router.push(`/my-addresses`)

           }else if(this.products !=[''] )
            {
            let address_id=document.getElementsByClassName('address')[0].value;
           
            this.products.forEach(element => {
                this.products_ids.push(element.prodId);
                this.products_quants.push(element.prdCount);
            })
          
           let parsedProducts_ids = JSON.stringify(this.products_ids);
           let parsedProducts_quants = JSON.stringify(this.products_quants);
			const formData = new FormData();
            formData.append('address_id', address_id);
            formData.append('products',  parsedProducts_ids);
            formData.append('quantities', parsedProducts_quants);
            
		    axios.post('order',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
				}
			})
			.then(( response ) => {
                    // router.push('/my-appointments');
			        console.log(response);
                    let products=JSON.stringify("");
			        localStorage.setItem('products',products);
                    const locale='en';
                    localStorage.setItem('reload-orders','1');
                    this.$router.push({ path: `/my-orders`,  params: { locale } })
                   // 
			} )
			.catch((error)=>{
			     console.log(error)
				})
            }
          
        },

        saveProducts(){
            let parsed = JSON.stringify(this.products);
            localStorage.setItem('products', parsed);



        },

        getAddresses()
		{	axios.get('address',{
        headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
        }})
			.then((response)=>{
				
				this.addresses=response.data.content.address;
				console.log("addresses"+this.addresses);
				

			})
			.catch((error)=>{
				console.log(error)
			})
		},


    },
 
};

</script>
<style>
#responsecart{
    color:rgb(168, 84, 84);
}
</style>