
<template>
<section>
				<div id="preloader">
					<div id="ctn-preloader" class="ctn-preloader">
						<div class="animation-preloader">
							<div class="icon"><img src="/images/contact-us-banner.png" alt="" class="m-auto d-block" width="200"></div>
							<div class="txt-loading mt-2 ltr-element ">
								
						
								
							    <span data-text-preloader="P" class="letters-loading">
									P
								</span>
								
								<span data-text-preloader="A" class="letters-loading">
									A
								</span>
								<span data-text-preloader="L" class="letters-loading">
									L
								</span>
								<span data-text-preloader="D" class="letters-loading">
									D
								</span>
								<span data-text-preloader="E" class="letters-loading">
									E
								</span>
								<span data-text-preloader="R" class="letters-loading">
									R
								</span>
								<span data-text-preloader="M" class="letters-loading">
									M
								</span>
								<span data-text-preloader="A" class="letters-loading">
									A
								</span>
							
								
							</div>
						</div>	
					</div>
				</div>
			</section>



<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#"> <img src="/images/icon-white.svg"></a>
  <button class="navbar-toggler" v-on:click="showlist()"  type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto ">
      
    

      <li class="nav-item active">
        <router-link class="nav-link main-nav-item" :to="{ name: 'HomePage' ,params:{locale:this.$i18n.locale} }" role="button">  {{$t('Home')}}</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link main-nav-item" :to="{ name: 'Services',params:{locale:this.$i18n.locale} }" role="button">  {{$t('Our Services')}}</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link main-nav-item" :to="{ name: 'Products',params:{locale:this.$i18n.locale} }" role="button">  {{$t('Our Products')}}</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link main-nav-item" :to="{ name: 'AboutUs',params:{locale:this.$i18n.locale} }" role="button"> {{$t('About Us')}}</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link main-nav-item" :to="{ name: 'ContactUs' ,params:{locale:this.$i18n.locale}}" role="button">   {{$t('Contact Us')}}</router-link>
      </li>
     
     

    </ul>

    <span v-if="!isAuth">  
      <span class="nav-link main-nav-item"  v-if="list==0"> <LocaleSwitcher></LocaleSwitcher></span>
     </span>
    <button v-if="!isAuth" class="secondary-btn">          
      <router-link class="secondary-btn-inside" :to="{ name: 'SignUp',params:{locale:this.$i18n.locale} }" role="button">  {{$t('Register')}}</router-link>                
     </button>

     <button v-if="!isAuth" class="primary-btn">        
         <router-link  class="primary-btn-inside" :to="{ name: 'SignIn' ,params:{locale:this.$i18n.locale}}" role="button">  {{$t('Login')}}</router-link>            
     </button>
     
     
     <span >
     <span v-if="isAuth">
      <router-link :to="{ name: 'ShippingCart' ,params:{locale:this.$i18n.locale}}" class="no-decoration-link-account h-icon">  
     <label>{{ getShippingNum }}</label> <img class="icon-header"  src="/images/shipping.png">
    </router-link>
    </span>
     <span   v-if="isAuth" class="header-user h-icon username">   <router-link :to="{ name: 'Account' ,params:{locale:this.$i18n.locale}}" class="no-decoration-link-account ">  
       <img class="icon-header"  src="/images/user-main.png"> {{username}}
      </router-link>
      <span v-if="list==0" class="header-user h-icon username"> <LocaleSwitcher></LocaleSwitcher></span>
      <span v-if="isAuth">
      <router-link :to="{ name: 'LogOut' }"  class="no-decoration-link account-padding">
               
                <img src="/images/logout-icon.png">
              
                     {{ $t('logout-0') }}
                  
                
            </router-link>  
          </span>
    </span>
  </span>
     


  </div>
</nav>
<div id="list" >
  <div class="home-padding-nav">
   <div class="row nav-mobile-row" style="padding-top:5%;" v-if="!isAuth" >
    <div class="col-12 ">
    <span >  
      
     </span>
    </div>
    </div>
    <div class="row nav-mobile-row " v-if="!isAuth" >
    <div class="col-6">

    <span   v-if="!isAuth && list==1" class="header-user h-icon username"> 
      <LocaleSwitcher></LocaleSwitcher>
  </span>
  </div>
  </div>
    <div class="row nav-mobile-row " v-if="!isAuth" >
      <div class="col-1"></div>
    <div class="col-5">
    <button class="secondary-btn signin-btn full-width navbar-btn-m">          
      <router-link class="secondary-btn-inside " :to="{ name: 'SignUp',params:{locale:this.$i18n.locale} }" role="button">  {{$t('Register')}}</router-link>                
     </button>
</div>
<div class="col-5">
     <button class="primary-btn  full-width navbar-btn-m">        
         <router-link  class="primary-btn-inside " :to="{ name: 'SignIn' ,params:{locale:this.$i18n.locale}}" role="button">  {{$t('Login')}}</router-link>            
     </button>
</div>
<div class="col-1"></div>
     </div>
     <span style="padding-top:40%;" v-if="isAuth">
      <router-link :to="{ name: 'ShippingCart' ,params:{locale:this.$i18n.locale}}" class="no-decoration-link-account h-icon">  
     <label>{{ getShippingNum }}</label> <img class="icon-header"  src="/images/shipping.png">
    </router-link>
    </span>

     <span   v-if="isAuth" class="header-user h-icon username">   <router-link :to="{ name: 'Account' ,params:{locale:this.$i18n.locale}}" class="no-decoration-link-account ">  
       <img class="icon-header"  src="/images/user-main.png"> {{username}}
      </router-link>
     
    </span>
    <span   v-if="isAuth && list==1" class="header-user h-icon username"> 
      <LocaleSwitcher></LocaleSwitcher>
  </span>
    <ul class="no-bullet mobile-items">
     
      <li class="nav-item active" v-on:click="showlist()">
        <router-link class="nav-link main-nav-item" :to="{ name: 'HomePage' ,params:{locale:this.$i18n.locale} }" role="button">  {{$t('Home')}}</router-link>
      </li>
      <li class="nav-item" v-on:click="showlist()">
        <router-link class="nav-link main-nav-item" :to="{ name: 'Services',params:{locale:this.$i18n.locale} }" role="button">  {{$t('Our Services')}}</router-link>
      </li>
      <li class="nav-item" v-on:click="showlist()">
        <router-link class="nav-link main-nav-item" :to="{ name: 'Products',params:{locale:this.$i18n.locale} }" role="button">  {{$t('Our Products')}}</router-link>
      </li>
      <li class="nav-item" v-on:click="showlist()">
        <router-link class="nav-link main-nav-item" :to="{ name: 'AboutUs',params:{locale:this.$i18n.locale} }" role="button"> {{$t('About Us')}}</router-link>
      </li>
      <li class="nav-item" v-on:click="showlist()">
        <router-link class="nav-link main-nav-item" :to="{ name: 'ContactUs' ,params:{locale:this.$i18n.locale}}" role="button">   {{$t('Contact Us')}}</router-link>
      </li>
    </ul>
   
   
    
     


  </div>
</div>
</template>


<script>
import { looseIndexOf } from '@vue/shared';
import axios from 'axios';
import { useRouter } from 'vue-router';
import LocaleSwitcher from '../../components/HomePage/LocaleSwitcher.vue';

export default {

name: 'Header',
components:{
  LocaleSwitcher
},
data() {
  return {
		isAuth:false,
    username:'',
    cartProducts:[],
    prNum:0,
    locale:'',
    list:''
  }
},
props: ['addToCart'],
mounted(){
this.isLoogedin();
// this.getShippingNum();
this.addShadow();
this.getlang();
},
computed: {

  getShippingNum(){
   let prNum=0
    if(localStorage.getItem("products") )
    {
      this.cartProducts= JSON.parse(localStorage.getItem("products")); 
      localStorage.getItem('this.cartProducts',this.cartProducts);
      if(this.cartProducts!=[])
      for(let i=0; i<this.cartProducts.length; i++)
        prNum++;
      
    } 
    console.log('this.$store.state');
    console.log(this.$store.state.prdNum);
    return  this.$store.state.prdNum;
    // return prNum;
    }
},
methods:{

getlang(){
  let lang=localStorage.getItem('locale');
  if(lang=='ar')
  {
    // document.getElementsByClassName('navbar-link').array.forEach(element => {
    //  element.style.padding="0px";
    // });
    
  }
},
isLoogedin(){
  let token=localStorage.getItem('token');
  if(token!=null)
  {
    this.isAuth=true;
    this.username=localStorage.getItem('username');
  }
  
},


addShadow(){
  window.addEventListener('scroll',(e)=>{
        const nav = document.querySelector('.navbar-light');
        if(window.pageYOffset>0){
          nav.classList.add("add-shadow");
        }else{
          nav.classList.remove("add-shadow");
        }
      });
},
showlist(){

   if(this.list==0){
   
    document.getElementById("list").style.display = "block";
     this.list=1;
   }

   else
   {
    document.getElementById("list").style.display = "none";
    this.list=0
   }

  // }
 
  

  // if(this.list==1)
  // {
  //   document.getElementById("list").style.display = "none";
  //   this.list=0;
  // }


  


  //document.getElementsById('list').style.display='block' ;

},


 }
}
  

    
</script>
