<template>

  <div class="portfolio-gallery-three mt-140 mb-150 lg-mt-90 lg-mb-100" data-aos="fade-out">
      <div class="container products-cont">
          <router-link :to="{ name: 'Products' }" class="no-decoration-link" >  <h4> {{$t('Products')}}</h4></router-link>                       
            <!-- <ul class="style-none category_nav isotop-menu-wrapper g-control-nav-one pb-30 lg-pb-10"> -->

               <button class="product_tabs" v-bind:class="{ activecategory: isActive[index] }"  v-for="(productCategory,index) in productsCategory" :key="productCategory.id" v-on:click="change_category(productCategory.id,index)">{{productCategory.categoryName}}</button>
          <!-- </ul> -->
          
          <!-- <div id="isotop-gallery-wrapper" class="grid-3column">
              <div class="grid-sizer "></div> -->
   <div class="contrainer">	
       <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 "  v-for=" (product,index) in products" :key="product.id">
              <router-link :to="  {name: 'Product' ,params:{locale:this.$i18n.locale,id:product.id,catid:choosencategory}}" class="no-decoration-link" v-if="index<4">
              
                  <div class="product-cont">
                      <img class="product-img" v-bind:src="product.productImage">
                      <div class="row ">
                          <div class="col-lg-9 col-md-7 col-sm-9 ">
                              <div class="prod-name ">
                                {{product.productName}}
                              </div>

                              </div>
                              <div class="row product-price-cont">
                                <div class="col-lg-9 col-md-7 col-sm-9 ">
                              <div class="prod-price">
                                 {{product.price}} ILS
                              </div>
                            </div>
                          
                          <div class="col-lg-3 col-md-5 col-sm-3 ">
                              <div class="circle-icon">+</div>
                          </div>
                      </div>
                  </div> 
                </div>
                  </router-link>  
                  
           </div>
            
      </div>
      <button class="primary-btn-no-border ">        
             <router-link :to="   {name: 'Products' ,params:{locale:this.$i18n.locale}}" class="no-decoration" role="button" > {{ $t('view-all-products') }}</router-link>                       

       </button>  
  </div>
</div>                      
        </div> <!-- /.portfolio-block-one -->
               
      
      <!-- </div> -->
                
  </template>
  
   
  <script>
  import { logicalExpression } from '@babel/types';
  import axios from 'axios';
  import { useRouter } from 'vue-router';



export default {
components: {
 
},

data() {
return {
productsCategory:[],
products:[],
choosencategory:0,
isActive:[]

};
},

setup() {
   
},
mounted() {
  window.scrollTo(0, 0);

  this.getProductCatyegories();

  setTimeout(() => {
    this.getNameDir();
  }, 5);
},
  
methods:{

  getNameDir()
  {
    this.lang=localStorage.getItem('locale');
    const collection = document.getElementsByClassName("prod-name");
    if(this.lang=='ar')
    {
        for (let i = 0; i < collection.length; i++) { 
          collection[i].style.textAlign = "right"; 
        }
        }
    else{
      for (let i = 0; i < collection.length; i++) {
          collection[i].style.textAlign = "left";
        }
    }
  },

  getProductCatyegories()
{
   this.productsCategory= JSON.parse(localStorage.getItem("productCategory"));
   this.choosencategory=this.productsCategory[0].id;
   console.log("productscategory"+this.productsCategory);
   this.products=this.productsCategory[0].product;
   console.log("products"+this.products);
   this.isActive[0]=true;

},

change_category(productCategory_id,index){
  this.choosencategory=productCategory_id;
  this.products=this.productsCategory.find(cat =>cat.id==productCategory_id).product;
  this.isActive=[];
  this.isActive[index]=true;
  setTimeout(() => {
    this.getNameDir();
  }, 5);
}
}
}
</script>