<template>
    <div class="container home-padding">
    <div class="row">

        <div class="col-lg-3 col-md-5 col-sm-6 side-bar-border">
            <side-bar-section> </side-bar-section>
        </div>
        <div class="col-lg-9 col-md-7 col-sm-6">

            <div class="row account-header">
              
              
             
               <!-- <div class="col-5 logout-btn"> 
                <router-link :to="{ name: 'LogOut' }"  class="no-decoration-link account-padding">
                <div class="col-lg-2">
                    <img src="/images/logout-icon.png">
                </div>
                  
                    <div class="col-lg-3">
                        LogOut
                    </div>
                </router-link>  
                </div>-->
        
            </div> 

            <br>
            <form>
      <div class="row ">
        <div class="col-lg-5 col-md-8 col-sm-6 container  ">
            <div class="contact-form">
                    <p class="contact-form">{{$t('Name')}}</p>
                     <input type="text" v-bind:value="username" class="form-input form-txt-input name-input">
                 </div>
        </div>

        <div class="col-lg-5 col-md-8 col-sm-6 container ">
            <div class="contact-form">
                    <p class="contact-form">{{$t('Email')}} </p>
                     <input type="text" v-bind:value="email" class="form-input form-txt-input email">
                 </div>
        </div>
             
    </div>

    <div class="row">
        <div class="col-lg-5 col-md-8 col-sm-6 container ">
            <div class="contact-form">
                    <p class="contact-form">{{$t('Phone number')}} </p>
                     <!-- <input style="direction: ltr;" type="text" v-bind:value="userphone" class="form-input form-txt-input phone"> -->
                     <span class="ltr-element">
                     <MazPhoneNumberInput 
										default-country-code="PS"
										v-model="userphone"
										
										>
					</MazPhoneNumberInput>
                </span>			
                    </div>
        </div>

        <div class="col-lg-5 col-md-8 col-sm-6 container ">
            <div class="contact-form">
                    <p class="contact-form" >{{ $t('date-of-birth') }}</p>
                     <input type="date" v-bind:value="age" class="form-input form-txt-input birth_date">
                 </div>
        </div>
             
    </div>


    <div class="row">
        <div class="col-lg-5 col-md-8 col-sm-6 container ">
            <div class="contact-form">
                    <p class="contact-form">{{$t('Gender')}}</p>
                    <div class="row " v-if="gender=='male'">
						<button class="col-lg-4 col-md-4 col-4 male-btn px-btn" v-on:click="change_gender('male')">{{ $t('Male') }}</button>
						<button class="col-lg-4 col-md-4 col-4 female-btn px-btn"  v-on:click="change_gender('female')">{{ $t('Female') }}</button>
														
					</div>
					<div class="row " v-else>
						<button class="col-lg-4 col-md-4 col-4 female-btn px-btn" v-on:click="change_gender('male')">{{ $t('Male') }}</button>
						<button class="col-lg-4 col-md-4 col-4 male-btn px-btn"  v-on:click="change_gender('female')">{{ $t('Female') }}</button>
														
					</div>
                 </div>
        </div>

        <div class="col-lg-5 col-md-8 col-sm-6 container update-profile">
            
            <div class="row margin-btn " >
						<button class="col-lg-5 col-md-6 col-11 male-btn " v-on:click="updateProfile()">{{ $t('update-profile') }}</button>
						<button class="col-lg-5 col-md-6 col-11 female-btn "  v-on:click="updatePassword()" >{{ $t('Change Password') }}</button>
														
			</div>
            </div> 
    </div>
</form>
</div>
   </div> 
</div>
   
</template>


<script>

import SideBarSection from '../../components/Profile/SideBar.vue';
import axios from 'axios';
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

export default {
name: "Account",
components: { 
    SideBarSection ,    
    MazPhoneNumberInput
 }, 
 css: [
    'maz-ui/css/main.css',
  ],

  build: {
    transpile: ['maz-ui'], 
  },

 mounted() {
this.getUserInfo();
window.scrollTo(0, 0);


},
setup(){
    localStorage.setItem('sidebarActive','0');

},

 data() {
    return {
      username:'',
      userimage:'',
      userphone:'',
      email:'',
      age:'',
      gender:'',
      isAuth:false
    };
  },



methods:{
    updateProfile(){
            let name=document.getElementsByClassName('name-input')[0].value;
            let image=document.getElementsByClassName('imagefile')[0].files[0];
            let gender=this.gender;
            console.log(image);
            let birth_date=document.getElementsByClassName('birth_date')[0].value;
            let email=document.getElementsByClassName('email')[0].value;
			const formData = new FormData();
            formData.append('name', name);
            formData.append('image', image);
            formData.append('gender', gender);
            formData.append('birth_date', birth_date);
            formData.append('email', email);
            localStorage.setItem('username',name);
		    axios.post('profile/update',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
				}
			})
			.then(( response ) => {
                console.log(response);
            //   location.reload()
			} )
			.catch((error)=>{
                console.log(error)
                document.getElementById("response").innerHTML = " حدثت مشكلة أثناء التعديل ، يرجى إعادة المحاولة";
	            document.getElementById('response').style.background="#edb2ad";
			    
                  setTimeout(() => {
                    document.getElementById("response").innerHTML ="";
                }, 5000);
                
                 
				})
               
			},

			 
       updatePassword(){
            this.$router.push('/update-password');
            },

    change_gender(gdr)
	{
		this.gender=gdr;
        
	},

    isLoogedin(){
  let token=localStorage.getItem('token');
  if(token!=null)
  {
    this.isAuth=true;
    this.username=localStorage.getItem('username');
  }
  
},

    getUserInfo()
		{	axios.get('profile',{
        headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
        }})
			.then((response)=>{
				
				this.username=response.data.content.name;
                this.userimage=response.data.content.userAvatar;
				this.userphone=response.data.content.phone;
                this.email=response.data.content.email;
                this.age=response.data.content.birthDate;
                this.gender=response.data.content.gender;
                console.log(response);

			})
			.catch((error)=>{
				console.log(error)
			})
		},
}
};
    



</script>
