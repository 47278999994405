<template >

    <service-book-page></service-book-page>
    
    </template>
    
    <script>
    import  ServiceBookPage from '../../components/Pages/BookService.vue';
    
    export default {
       name: "ServiceBook",
        components: { 
           ServiceBookPage,
                        }, 
        };
    
    </script>