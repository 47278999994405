<template>
    <!-- ===================================================
				Loading Transition
			==================================================== -->
         
			<header-section></header-section>


			<!-- 
			=============================================
				Theme Hero Banner
			============================================== 
			-->

			<router-view></router-view>
            <!--
			=====================================================
				News Letter Section
			=====================================================
			-->
			<!-- <news-letter-section> </news-letter-section> -->
			<!--
			=====================================================
				Footer Section
			=====================================================
			-->
            <footer-section></footer-section>
			<!--  ==========================================   -->
			<!-- <button class="scroll-top">
				<i class="bi bi-arrow-up-short"></i>
			</button> -->

</template>

<script>
import HeaderSection from '../../components/HomePage/Header.vue';


// import NewsLetterSection from '../../components/HomePage/NewsLetter.vue';
import FooterSection from '../../components/HomePage/Footer.vue';
import axios from 'axios';


export default {

   name: "Master",
    components: { HeaderSection,
				  FooterSection,
				    }, 
		data() {
			return {
			lang:''
			
			};
		},

methods:{
	gethome()
		{

			

			if(!localStorage.getItem('products'))
			{
				let products=JSON.stringify("");
				localStorage.setItem('products',products);
			}
		
			var locale = localStorage.getItem('locale')
			axios.get('home',{
			headers:{
				"Accept-Language": locale
				}
			})
			.then((response)=>{
				console.log(response);
				
				localStorage.setItem("productCategory", JSON.stringify(response.data.content.productCategory));
				localStorage.setItem("serviceCategory", JSON.stringify(response.data.content.serviceCategory));
				localStorage.setItem("discount",JSON.stringify(response.data.content.disaccount))
				let lang=localStorage.getItem('locale');
				this.lang=lang;
				if (lang=='ar')
				{
					document.querySelector('html').style.direction='rtl';
					document.querySelector('html').classList.add('arabic-font');

				}
				
				
				fbq('track', 'Contact');

			})
			.catch((error)=>{
				console.log(error)
			})
		}
		},


		mounted(){
			this.gethome();
		
		},
		setup(){
			
		}
    };



</script>
