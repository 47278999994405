<template>
    <div class="blog-section-three position-relative products-cont">
				<div class="container ">
                    <router-link :to="{ name: 'Services' ,params:{locale:this.$i18n.locale} }" class="no-decoration-link ">  

                    <h4 class="dep-title title-home">{{$t('Our Departments')}} </h4>
				</router-link>
					<div class="row gx-xxl-5 ">
                       
						<div class="col-lg-4  d-flex  col-md-6 col-sm-12 d-flex container" v-for="(serviceCategory,index) in servicesCategory"  :key="serviceCategory.id" data-aos="fade-up">     

                            <div class="img-text-container" v-if="index<=5">
                                <router-link :to="{ name: 'SubServices' ,params:{locale:this.$i18n.locale,id:serviceCategory.id} }" class="no-decoration-link-services ">  
                                    
                                    <img v-bind:src="serviceCategory.categoryImage" class="service_img" id="service_img" alt="Snow" >
                                    <div class="centered">{{serviceCategory.categoryName}}</div>
                                </router-link>
                            </div>
                         </div>
                    </div>
				
					<button class="primary-btn-no-border ">        
             <router-link :to="   {name: 'Services' ,params:{locale:this.$i18n.locale}}" class="no-decoration" role="button" > {{ $t('view-all-services') }}</router-link>                       

       </button>
             
                       
				</div>

        </div>
</template>

 
<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
import { useRouter } from 'vue-router';



export default {


data() {
return {
servicesCategory:[],
services:[],
choosencategory:0,
isActive:[],
 
};
},

setup() {
 
},
mounted() {
this.getServiceCategories();
//this.changebackgroundimages();
},

methods:{
    getServiceCategories()
{
   
 this.servicesCategory= JSON.parse(localStorage.getItem("serviceCategory"));
 console.log("servicesCategory"+this.servicesCategory);
 var locale = localStorage.getItem('locale');
 if(this.servicesCategory==null)
 {
   
			axios.get('home',{
			headers:{
				"Accept-Language": locale
				}
			})
			.then((response)=>{
				console.log(response);
				
				localStorage.setItem("productCategory", JSON.stringify(response.data.content.productCategory));
				localStorage.setItem("serviceCategory", JSON.stringify(response.data.content.serviceCategory));
				let lang=localStorage.getItem('locale');
				this.lang=lang;
				if (lang=='ar')
				{
					document.querySelector('html').style.direction='rtl';
					document.querySelector('html').classList.add('arabic-font');

				}
				
				location.reload();

			})
			.catch((error)=>{
				console.log(error)
			})
            
		
 }

},


}
}
</script>