<template>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css">

    <div class="row home-padding-signup">
		<div class="col-sm-12">
				
					<div class="inner-wrapper">
						<div class="row ">	
							<div class=" col-lg-5 col-md-12 col-sm-8 padding-sign-up  ">
								<img class="img-signup" src="/images/nurse5.svg">							
							</div>
						<div	class="col-lg-7 col-md-12 col-sm-4  hello" style="display: none;"  id="v-otp-div">
							<h4>{{ $t('verify-code') }}</h4>
							<br>
							<p class="secondary-text">{{ $t('we-have-sent-verify-code-to-your-phone-number') }}</p>
							<br>
							<div id="response2"></div>
							<div class="form-group input-div  ltr-element "  >
								<v-otp-input
									ref="otpInput"
									input-classes="otp-input"
									separator="-"
									:num-inputs="6"
									:should-auto-focus="true"
									:is-input-num="true"
									:conditionalClass="['one', 'two', 'three', 'four','five','six']"
									:placeholder="[]"
									@on-change="handleOnChange"
									@on-complete="handleOnComplete"
								/>
								<br>
								<div class="row">
									
									<div class="col-6">
										<div class="col-6">
										<button class=" primary-btn padding-btn" :disabled="!completed">
                                    {{ $t('verify-0') }}
                              		  </button>
										<div class="col-6"></div>
									</div>

									<div class="col-6">
										<!-- <button class="secondary-btn padding-btn" @click="clearInput()">{{ $t('clear-input') }}</button> -->
									</div>
									</div>
									
									
								</div>
                               
  				</div>
			</div>

							<div class="col-lg-7 col-md-12 col-sm-4  hello" id="signup-form">
								<h3>{{ $t('create-new') }}</h3>
								<h3>{{ $t('account') }}</h3>
								
								<div class="col-sm-10 ">
								<form action="#" @submit.prevent="submit" >
									<div id="response"></div>
									
                                    <div class="form-group input-div">
										<label class="form-group">{{ $t('full-name') }}</label>
										<label class="form-group input-error" v-if="name_error">{{ $t('field-required') }}</label>
										<input type="text"  required name="name" class="form-control name" id="exampleInputEmail1  name"  >
									</div>
									
									<div class="form-group input-div">
										<label class="form-group">{{ $t('Email') }}</label>
										<label class="form-group input-error" v-if="email_error">{{ $t('field-required') }}</label>

										<input type="email" required name="email" class="form-control email" id="exampleInputPassword1 email">
									</div>
									<label class="form-group">{{ $t('phone-number') }}</label>
									<label class="form-group input-error" v-if="phone_error">{{ $t('field-required') }}</label>

                                    <div class="form-group input-div ltr-element" >
										<div class="form-group  ltr" >
										<MazPhoneNumberInput 
										default-country-code="PS"
										v-model="phone">
										</MazPhoneNumberInput>	
									</div>
									
									</div>

									
                                    <div class="form-group input-div">
										
										<label class="form-group">{{ $t('password') }}</label>
										<label class="form-group input-error" v-if="password_error"> {{ $t('field-required') }} </label>
										<label class="form-group input-error" v-if="password_length_error"> {{ $t('at-least-8-charachters') }} </label>
										
									
       										
										<div class="row ">
										<div class="col-lg-12 col-md-12 col-sm-12 container  ">
											<div class="contact-form">
                  
											<input type="password" v-bind:value="password" name="password" required="" class="form-input form-txt-input password"  id="id_password">
											<!-- <input type="password" name="password" class="form-input form-txt-input password" autocomplete="current-password"  id="id_password"> -->
											<i class="far fa-eye" id="togglePasswords" style=""></i>
											</div>
										</div>
									</div>
										<!-- <input type="password" required="" class="form-input form-txt-input password"  id="id_password"> -->

									
									</div>
									<div class="row">
										<div class="col-5">

										
                                    <div class="form-group input-div">
										
											
												<label class="form-group">{{ $t('date-of-birth') }}</label>
												<label class="form-group input-error" v-if="birth_date_error">{{ $t('field-required') }}</label>

													<input type="date" required name="birth_date" class="form-control birth_date" id="exampleInputPassword1 birth_date" >
											</div>
										</div>
										
										<div class="col-7">
                                    <div class="row form-group input-div container">
										
												<label class="gender">{{ $t('Gender') }}</label>
												<div class="row " v-if="gender=='male'">
													<button class="col-lg-4 col-md-4 col-4 male-btn px-btn" v-on:click="change_gender('male')">{{ $t('Male') }}</button>
													<button class="col-lg-4 col-md-4 col-4 female-btn px-btn"  v-on:click="change_gender('female')">{{ $t('Female') }}</button>
														
												</div>
												<div class="row " v-if="gender=='female'">
													<button class="col-lg-4 col-md-4 col-4 female-btn px-btn" v-on:click="change_gender('male')">{{ $t('Male') }}</button>
													<button class="col-lg-4 col-md-4 col-4 male-btn px-btn"  v-on:click="change_gender('female')">{{ $t('Female') }}</button>
														
												</div>
												<input type="text" class="gender" id="gender" style="display: none;" name="gender" v-bind:value="gender" >
												</div>
											</div>
										<div>
									</div>
										<label class="create-account"> {{ $t('have-an-account') }}? <strong> <router-link class="no-decoration-link brown" :to="{ name: 'SignIn',params:{locale:this.$i18n.locale} }" role="button"> {{ $t('Login') }}</router-link> </strong></label>
									</div>
									<div
      									id="recaptcha-container"
      										class="justify-center flex"
 										></div>    
										 <input name="phone" class="phone" id="phone" style="display: none;"  v-bind:value="phone">
									 <!-- <input type="text"  required name="code" class="form-control" id="code exampleInputPassword1" placeholder="insert code here"> -->
									 <div id="response1"></div>
									 <div class="row">
										<div class="col-6 ">
											
												 <div class="primary-btn">    
										<button v-on:click="firebaseAuth()"  class="primary-btn-inside">{{ $t('sign-up') }}</button>    
      							   </div>
								</div>
							</div>
								</form>
							</div>						
						</div>
					</div>	
				</div>
         	</div>
        </div>

       
</template>


<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
import { useRouter } from 'vue-router';
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
//import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber  } from "firebase/auth";
import { ref } from "vue";
import VOtpInput from 'vue3-otp-input';

export default {

	css: [
    'maz-ui/css/main.css',
  ],

  build: {
    transpile: ['maz-ui'], 
  },

name: 'SignUp',
components: { MazPhoneNumberInput , VOtpInput},
//

data() {
    return {
	message:'',
	gender:'male',
	phone:'',
	code:'',
	name_error:false,
	password_error:false,
	phone_error:false,
	email_error:false,
	birth_date_error:false,
	password_length_error:false,
	lang:'ar'
    };
  },
  methods:{
	change_gender(gdr)
	{
		this.gender=gdr;
		
	},

	getlocale(){
	  this.lang=localStorage.getItem('locale');
	  if(this.lang=='en')
	  {

       document.getElementById('togglePasswords').style.marginLeft='-40px';
	  }
	  else
	  document.getElementById('togglePasswords').style.marginRight='-40px';
	},

	showcode(){
		document.getElementById('signup-form').style.display='none';
		document.getElementById('v-otp-div').style.display='block';
	},

	
	firebaseAuth(){
		let name=document.getElementsByClassName('name')[0].value;
		let password=document.getElementsByClassName('password')[0].value;
		let phone=document.getElementsByClassName('phone')[0].value;
		let email=document.getElementsByClassName('email')[0].value;
		let gender=document.getElementsByClassName('gender')[0].value;
		let birth_date=document.getElementsByClassName('birth_date')[0].value;

		if(name=='' || name==undefined || !name)
		{
			 this.name_error=true;
			 window.scrollTo(0,0);
			 setTimeout(() => {
				this.name_error=false;
			}, "7000");
			return;
		}

		if(email=='' || email==undefined || !email)
		{
			 this.email_error=true;
			 window.scrollTo(0,0);
			 setTimeout(() => {
				this.email_error=false;
			}, "7000");
			return;
		}

		if(phone=='' || phone==undefined || !phone)
		{
			 this.phone_error=true;
			 window.scrollTo(0,0);
			 setTimeout(() => {
				this.phone_error=false;
			}, "7000");
			return;
		}
	
		
		if(password=='' || password==undefined || !password)
		{
			 this.password_error=true;

			 setTimeout(() => {
				this.password_error=false;
			}, "7000");
			return;
		}

		if(password.length<8)
		{
			this.password_length_error=true;
			setTimeout(() => {
				this.password_length_error=false;
			}, "7000");
			return;
		}

		if(birth_date=='' || birth_date==undefined || !birth_date)
		{
			 this.birth_date_error=true;

			 setTimeout(() => {
				this.birth_date=false;
			}, "7000");
			return;
		}

		if(gender=='' || gender==undefined || !gender)
		{
				gender='male';
		}

		localStorage.setItem('PhoneNumber',this.phone);
		const auth = getAuth();
		window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
		'size': 'normal',
		'callback': (response) => {
			// reCAPTCHA solved, allow signInWithPhoneNumber.
			// ...
			console.log(12);
		},
		'expired-callback': () => {
			// Response expired. Ask user to solve reCAPTCHA again.
			// ...
			console.log(34);
		}
		}, auth);


		recaptchaVerifier.render().then((widgetId) => {
		window.recaptchaWidgetId = widgetId;
		});

		const appVerifier = window.recaptchaVerifier;
		signInWithPhoneNumber(auth, this.phone, appVerifier)
			.then((confirmationResult) => {
			// SMS sent. Prompt user to type the code from the message, then sign the
			// user in with confirmationResult.confirm(code).
			document.getElementById("response1").innerHTML = "Code has been sent, please wait to verify";
			console.log("confirmationResult"+confirmationResult);

			window.confirmationResult = confirmationResult;
			//   this.getCodeFromUserInput();
			this.showcode();
			// ...
			}).catch((error) => {
				grecaptcha.reset(window.recaptchaWidgetId);

				// Or, if you haven't stored the widget ID:
					window.recaptchaVerifier.render().then(function(widgetId) {
					grecaptcha.reset(widgetId);

					
					console.log(99);
				});
			});

			
		}
  },

  mounted(){
	window.scrollTo(0, 0);
	this.getlocale();
	const togglePassword = document.querySelector('#togglePasswords');
 	 const password = document.querySelector('#id_password');

 	 togglePassword.addEventListener('click', function (e) {
 	   // toggle the type attribute
     const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
     password.setAttribute('type', type);
     // toggle the eye slash icon
     this.classList.toggle('fa-eye-slash');
	
});

  },

  setup(){
	const router=useRouter();
	const otpInput = ref(null)
    const handleOnComplete = (value ) => {
	console.log('OTP completed: ', value);
    window.confirmationResult.confirm(value).then((result) => {
	// User signed in successfully.
	console.log('checked');
	const user = result.user;
	console.log(confirmationResult);
	console.log(user);
	console.log(56);
		
	 
		
		let name=document.getElementsByClassName('name')[0].value;
		let password=document.getElementsByClassName('password')[0].value;
		let phone=document.getElementsByClassName('phone')[0].value;
		let email=document.getElementsByClassName('email')[0].value;
		let gender=document.getElementsByClassName('gender')[0].value;
		let birth_date=document.getElementsByClassName('birth_date')[0].value;
		const form =new FormData();
		form.append('name', name);
		form.append('password', password);
		form.append('phone', phone);
		form.append('email', email);
		form.append('gender', gender);
		form.append('birth_date', birth_date);
		
		axios.post('auth/register',form).then(async (response)=>{	
		
		let userid=response.data.content.id
 		const formData = new FormData();
         formData.append('phone', phone);
		 formData.append('code',value);
		 await axios.post('auth/verifyCode',formData,{
			headers:{
				"user_id": userid
				}}).then((res)=>{	
					localStorage.setItem('token',res.data.content.accessToken);
					localStorage.setItem('username',res.data.content.name);
					let products=JSON.stringify("");
					console.log(res);

					localStorage.setItem('products',products);	
					localStorage.setItem('reloadLogin','1');
					
					router.push('/');
					
			})
	 })	;	
	  }
				
				).catch((error) => {
				document.getElementById("response2").innerHTML = "Bad Result, Please try Again";
				console.log(error);
				}
			
			)};

				const handleOnChange = (value) => {
				console.log('OTP changed: ', value);
				};

				const clearInput = () => {
				otpInput.value.clearInput()
				}

				return { handleOnComplete, handleOnChange, clearInput, otpInput };




    },

	

   

};
</script>

<style>
.gender{
	padding-top: 3%;
}

label{
	padding-bottom: 1%;
	font-size: 12px;
}

#togglePasswords{
    margin-right: -30px; 
    margin-top: 15px;
    position:absolute;
    z-index: 10;
	
    cursor: pointer;
}
</style>