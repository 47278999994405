<template >

    <Palderma-Friends-page></Palderma-Friends-page>
    
    </template>
    
    <script>
    import  PaldermaFriendsPage from '../../components/Pages/PaldermaFriends.vue';
    
    export default {
       name: "PaldermaFriends",
        components: { 
            PaldermaFriendsPage,
                        }, 
        };
    
    </script>