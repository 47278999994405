<template >
    <div class="fancy-feature-seventeen  ">
        <div class="container about-container">
            <div class="row align-items-center ">
                <div class="col-xl-6 col-sm-5  col-md-6 col-lg-6" data-aos="fade-right">
                    <div class="title-style-three  text-lg">
                        <img class="palderma-pales-img" src="/images/palderma_pales.svg">
                       
                    </div> <!-- /.title-style-three -->
                </div>
               
                <div class="col-xl-6 col-sm-7 col-md-6 col-lg-6  " data-aos="fade-left" >
                    <div class="container">
                  <!-- <img class="book-appointment-banner" src="/images/booking-nurse.svg">  -->
                    <h3>{{$t('About Palderma Center')}}</h3>

         
            <div class="blog-section-three position-relative  ">
				
					<div class="row gx-xxl-12  about-pal">
						<div class="col-lg-12  col-md-12 col-sm-12 d-flex " data-aos="fade-up">
                             <div class="row ">   
                                  <div class="col-md-2  col-sm-2  ">
                                        <img src="/images/modern_logo.svg">
                                 </div>
                            <div class="col-md-10 col-sm-10 ">
                                  <strong>{{$t('Modern Clinic')}}</strong>
                                      <p class="secondary-text"> {{ $t('the-clinic-relies-on-modernity-in-every-detail-from-design-to-devices-and-tools-to-the-completion-of-the-latest-operations-to-reach-the-best-results') }}</p>
                             </div>
                        </div>	
					</div>
							

                    <div class="row gx-xxl-12  about-pal">
						<div class="col-lg-12  col-md-12 col-sm-12 d-flex " data-aos="fade-up">
                             <div class="row ">   
                                  <div class="col-md-2  col-sm-2  ">
                                        <img src="/images/consult_logo.svg">
                                 </div>
                            <div class="col-md-10 col-sm-10 ">
                                  <strong>{{$t('Less Consultation Fees')}}</strong>
                                      <p class="secondary-text"> {{ $t('the-basis-of-our-business-is-price-competition-to-achieve-high-savings-for-customers-along-with-perfect-service') }}</p>
                             </div>
                        </div>	
					</div>
                    </div>
					

                    <div class="row gx-xxl-12 about-pal">
						<div class="col-lg-12  col-md-12 col-sm-12 d-flex " data-aos="fade-up">
                             <div class="row ">   
                                  <div class="col-md-2  col-sm-2  ">
                                        <img src="/images/profess_logo.svg">
                                 </div>
                            <div class="col-md-10 col-sm-10 ">
                                  <strong>{{$t('Professional Treatment')}}</strong>
                                      <p class="secondary-text"> {{ $t('our-experts-put-their-energies-and-expertise-under-customer-service-and-provide-the-necessary-care-as-our-first-concern-is-to-gain-customer-satisfaction-in-terms-of-results-and-treatment') }}</p>
                             </div>
                        </div>	
					</div>
                </div>
						</div>					
					</div>
				
			</div> <!-- /.blog-section-three -->
         </div>
     </div>
</div>


           
        </div> <!-- /.container -->

      </template>