<template>
    
        <div class="container about-us-cont home-padding">
            <div class="no-decoration-link" >  <h4> {{ $t('About Us') }}</h4></div>   
         </div>                    
    <div class="container about-us-center" data-aos="fade-in" >
    <div class="row">

       
        <div class="col-lg-5  col-md-12 col-sm-12 about-us-img " >
            <div class="contact-banner-text center">
                <img src="/images/contact-us-banner.png">
               
            </div>
            </div>
            <div class="col-lg-6  col-md-12 col-sm-12 " >
            <div class="contact-location container secondary-text">
                <h6><b>{{ $t('about-palderma') }}</b></h6>
                <p >{{ $t('about-us') }}</p>
                <p> {{$t('    The first center in Palestine for the treatment of all hair problems, treatment of genetic baldness, wrinkless, cosmetic surgery, laser hair removal, cold chemical peeling, tightening breasts in women, filler and Botox injection')}}
</p>
            <h6><b>{{$t('location')}}</b></h6>
            <div class="row ">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 secondary-text">
                   
                    <p>{{ $t('alkaleel-ein-sara-st-alhussein-stad-almahawer') }}</p>
                </div>
            </div>
        </div>
</div>
</div>
    
</div>
   
</template>


<script>

import SideBarSection from '../../components/Profile/SideBar.vue';

export default {
name: "AboutUs",
mounted() {
    window.scrollTo(0, 0);

},

components: { 
    SideBarSection ,    
   
 }, 
};

</script>