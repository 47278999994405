<template>
    
    <span class="lang-switch"><img class="planet-icon" src="/images/global.png">
    <select   @change="switchlocale()" class="selectloc lang-switch">
        <option  v-for="locale in locales" :key="locale"  v-bind:value="locale">
             <span v-if="locale=='ar'">AR</span> 
             <span v-if="locale=='en'">EN</span> 
        </option>
    </select>
</span>
        <!-- <li v-for="locale in locales" :key="locale" @click="switchlocale(locale)">{{ locale }}</li> -->
   
      
  
</template>

<script>
import { datetime } from '@intlify/core-base';
import axios from 'axios';
export default{
    name:'LocaleSwitcher',
setup(){
   
},
    data(){
        return{
            locales:process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
            selectedLocale: ''
        }
    
    },
    mounted(){
        this.getlocale();
        document.getElementsByClassName("selectloc")[0].value=localStorage.getItem('locale');
        if(!localStorage.getItem('locale'))
        {
            localStorage.setItem('locale','ar')
        }
    },
    methods:{
        switchlocale(){
        //    if(this.$i18n.locale!==this.selectedLocale)
            {
                this.selectedLocale=document.getElementsByClassName("selectloc")[0].value;
                this.$i18n.locale=this.selectedLocale;
                    localStorage.setItem('locale', this.selectedLocale );
                    var locale = localStorage.getItem('locale')
			axios.get('home',{
			headers:{
				"Accept-Language": locale
				}
			})
			.then((response)=>{
				console.log(response);
				
				localStorage.setItem("productCategory", JSON.stringify(response.data.content.productCategory));
				localStorage.setItem("serviceCategory", JSON.stringify(response.data.content.serviceCategory));
				let lang=localStorage.getItem('locale');
				this.lang=lang;
                location.reload();
                let to=this.$router.resolve({params:{locale}});
               
				// if (lang=='en')
				// {
				// 	document.querySelector('html').style.direction='ltr';
				// 	document.querySelector('html').classList.add('arabic-font');

				// }
				
				

			})
			.catch((error)=>{
				console.log(error)
			})
		
                   this.$router.push(to.location);
                //  this.$router.push('/');
                   
            }
        },
        getlocale()
        {
         //   this.selectedLocale=this.$i18n.locale;

        }
    }
}
</script>
<style>

</style>

