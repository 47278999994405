<template >

    <my-appointments-page></my-appointments-page>
    
    </template>
    
    <script>
    import  MyAppointmentsPage from '../../components/Profile/MyAppointments.vue';
    
    export default {
       name: "MyAppointments",
        components: { 
            MyAppointmentsPage,
                        }, 
        };
    
    </script>