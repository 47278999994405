
<template>
    <div class="container products-cont" data-aos="fade-out" v-if="paldermaFriends.length>0"> 
         <router-link    :to="{ name: 'PaldermaFriends' ,params:{locale:this.$i18n.locale} }" class="no-decoration-link " >  <h4 class="title-home"> {{$t('Palderma friends')}} </h4></router-link>                
      
         <swiper :navigation="true" :modules="modules" :slides-per-view="4"
    :space-between="20" class="mySwiper">
      <swiper-slide  class="friend-slide" v-for="paldermaFriend in paldermaFriends" :key="paldermaFriend.id">
        <router-link  :to="{ name: 'PaldermaFriend' ,params:{locale:this.$i18n.locale,id:paldermaFriend.id} }"   class="no-decoration-link">
          <!-- <router-link :to="  {name: 'PaldermaFriend' ,params:{locale:this.$i18n.locale,id:paldermaFriend.id}}" class="no-decoration-link"> -->

          <div class="slide-content">
            <div class="slide-wrapper">
                <div class="card">
                    <div class="image-content">
                        <span class="overlay">
                            <div class="card-image">
                                <img v-bind:src="paldermaFriend.paldermaFrindeImage" class="card-img" >
                            </div>
                            <div class="card-content">
                                 <h4 class="name"> {{paldermaFriend.paldermaFrindeName}}</h4> 
                                 <p class="spec">
                                    {{paldermaFriend.specialization}}
                                 </p>  
                            </div>
                            <button class="button book-btn">  {{$t('Book Now')}}  </button>
                        </span>
                    </div>
                </div>
                
            </div>
        </div>   
        </router-link>
     </swiper-slide>
     
    </swiper>
</div>
  </template>
  <script>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import axios from 'axios';
    // Import Swiper styles
    import 'swiper/css';
  
    import 'swiper/css/navigation';  
    // import required modules
    import { Navigation } from 'swiper';
  
    export default {
      components: {
        Swiper,
        SwiperSlide,
      },
      setup() {

        return {
          
      
          modules: [Navigation],
        };
      },

      data() {
        return {
            paldermaFriends:[]
        };
      },


      methods:{
	getfriends()
		{	axios.get('paldermaFrinde')
			.then((response)=>{
				console.log("friends:"+response);
                this.paldermaFriends=response.data.content.paldermaFrinde;
				localStorage.setItem("paldermaFriends", JSON.stringify(this.paldermaFriends));

			})
			.catch((error)=>{
				console.log(error)
			})
		}
		},


		mounted(){
			this.getfriends();
		}
    };
   
  </script>
  