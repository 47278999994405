<template>
    <div class="blog-section-three position-relative home-padding">
				<div class="container dep-container">
                 <!-- <h4 class="dep-title ">Our S</h4> -->
				<div class="row gx-xxl-5 ">
						<div class="col-lg-11  col-md-12 col-sm-12 d-flex container " data-aos="fade-up">     
                           <div class=" health-img sub-service-img-txt  sub-service-main">
                                <h4> {{servicesCategory.categoryName}}</h4>
                            </div>   

                            <!-- <div class="img-text-container">
                                
                                    <img  v-bind:src="servicesCategory.categoryImage" class="service_img_sub" alt="Snow" style="width:80%;">
                                    <div class="centered">{{servicesCategory.categoryName}}</div>
                               
                            </div> -->
						</div>
        </div>  


              <div class="row gx-xxl-5 ">
                   <div class="col-lg-11  col-md-11 col-sm-12 d-flex container " data-aos="fade-up">     

                     <div class="col-lg-4   col-md-6 col-sm-6 col-6 d-flex "  v-for="subservice in subservices"  data-aos="fade-up">
                            <div class="sub-service-card">
                              <router-link :to="{name: 'BookService' ,params:{locale:this.$i18n.locale,id:subservice.id,catid:servicesCategory.id}}" class=" no-decoration-service"> 
                              
                              <div class="sub-service-name">
                                  <p> {{subservice.serviceName}}</p> 
                              </div>
                                  <p> session: {{subservice.session}}</p> 
                             
                        <div class="sub-service-card-inside">
                          {{subservice.price}}<span v-if="subservice.price !== 'Price is according to the quantity of threads'"> ILS</span>
                        </div>
                      </router-link>
                      </div> 
                   
 
                    </div> 
                       
                       
                    
                   </div>
            </div>
            <!--  -->
        </div>
     </div>
        

</template>
   
   <script>
   import { logicalExpression } from '@babel/types';
   import axios from 'axios';
   import { useRoute } from 'vue-router'
   export default {
   
   name: 'SubServices',
   data() {
       return {
        categoryid:'',
        serviceCategories:[],
        subservices:[],
        servicesCategory:[]
        
       };
     },
     computed(){
     
        this.categoryid = route.params.id;
        
        this.get_category_info();
       
     },
    
     mounted() {
      
        window.scrollTo(0, 0);
      
        const route = useRoute();
        this.categoryid = route.params.id;
        this.get_category_info();
        
    },
      
    methods:{
      get_category_info()
       {
       
            this.servicesCategory= JSON.parse(localStorage.getItem("serviceCategory"));
            this.servicesCategory=this.servicesCategory.find(cat =>cat.id==this.categoryid);
            this.subservices=this.servicesCategory.services;
            this.serviceImg=this.servicesCategory.categoryImage;
            console.log( this.servicesCategory);
            //document.getElementsByClassName('sub-service-main')[0].style.backgroundImage="url("+this.serviceImg+")";
            console.log('subservices'+this.subservices);   
       },
    }
    }
   </script>