<template >

    <Products-page></Products-page>
    
    </template>
    
    <script>
    import  ProductsPage from '../../components/Pages/Products.vue';
    
    export default {
       name: "Products",
        components: { 
           ProductsPage,
                        }, 
        };
    
    </script>