<template>
 <!-- <form  action="#" @submit.prevent="PostAppointment()" id="submit_appointment">        -->

    <div class="fancy-feature-seventeen position-relative home-padding" data-aos="fade-left">
           <div class="container book-appointment-container">
               <div class="row align-items-center book-appointment-txt">
                   <div class="col-xl-6 col-lg-5 book-image-cont" >
                       <!-- <img class="book-appointment-banner" src="/images/booking-nurse.svg"> -->
                               <img class="book-appointment-banner" v-bind:src="service.serviceImage">
                                
                      </div>
                       
                 


                <div class="col-xl-6 col-lg-7 friend-container" data-aos="fade-left" >
                    
                    <div class="title-style-three   book-doctor-row">
                        <h4>{{service.serviceName}}</h4>
                        <div class="row srv-details">
                            <div class="col-lg-4 col-md-3 col-sm-3 col-6 book-srv-title">
                                  {{category.categoryName}}
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6 book-srv-price">
                                {{service.price}}<span v-if="service.price !== 'Price is according to the quantity of threads'"> ILS</span>

                            </div>
                        </div>
                        <p class="secondary-text">
                            {{service.serviceDescription}}                        </p>
                        <div v-if="lang=='en'" class="month-year ">
                            <h5>
                            <button @click="previousmonth()" class="month-pre"> <img class="arrows-icon" src="/images/chevron.png"> </button>
                           <span class="month-year-input">{{monthname}} - {{startyear}}</span> 
                            <button @click="nextmonth()" class="month-next"><img class="arrows-icon" src="/images/right.png"> </button>
                        </h5>   
                        </div>

                        <div v-else class="month-year ">
                            <h5>
                             <button @click="nextmonth()" class="month-next"><img class="arrows-icon" src="/images/right.png"> </button>
                                  <span class="month-year-input">{{monthname}} - {{startyear}}</span> 
                            <button @click="previousmonth()" class="month-pre"> <img class="arrows-icon" src="/images/chevron.png"> </button>
                        </h5>   
                        </div>
                        <div class="ltr-element">
                        <swiper  :modules="modules" :slides-per-view="6"   @swiper="onSwiper"
                            :space-between="10" class="mySwiper">
                            <swiper-slide v-for="(dname ,index) in dayname" :key="index">
                                <div class="slide-content">
                                    <div class="slide-wrapper">      
                                        <!--- -  -->
                                        <div round color="light" v-bind:class="{ activedaybtn: isActive[index], disabledSlide:(((startyear==nowyear)&&(nowmonth==startmonth)&&(index<nowday))) }" class="scroll-click" v-if="index !==0" v-on:click="changeday(index)">
                                                <ion-label class="rest-id" selected>{{index}} <br>{{dname}}</ion-label>
                                        </div>
                                    </div>
                                </div>   
                            </swiper-slide>
                        </swiper>
                    </div>
                   </div>

                   <div class="title-style-three  book-doctor-row">
                        <h6>{{ $t('appointment-time') }}</h6>
                        <!-- <input class="book-input form-control" type="text" placeholder="12:00  12:30"> -->
                  <select required name="time" id="time" class="time book-input form-control">
                    <option class="hour-option" v-for=" hourOption in hourOptions" :key="hourOption.id" v-bind:value=hourOption>{{ hourOption }}</option>
                  </select>
                    </div>

                    <div class="title-style-three   book-doctor-row">
                        <h6> {{ $t('notes') }}</h6>
                        <textarea class="note book-input-text form-control"></textarea>
                    </div>
                    <div id="response"></div>
                    <button v-if="isAuth" v-on:click="PostAppointment()" class="primary-btn-no-border border-radius-btn no-decoration">        
                           {{ $t('book-service') }}     
                    </button>
                    <router-link :to="{ name: 'SignIn' ,params:{locale:this.$i18n.locale}}" class="no-decoration"  v-else>
                            <button   class="primary-btn-no-border border-radius-btn no-decoration" >        
                                {{ $t('book-service') }}    
                            </button>
                   </router-link>
               </div>


              
					        
          
        </div>
              </div>
           
           </div> <!-- /.container -->    
        <!-- </form> -->
   </template>
      
      
      <script>
      import { logicalExpression } from '@babel/types';
      import axios from 'axios';
      import { Swiper, SwiperSlide } from 'swiper/vue';
      import { useRouter,useRoute } from 'vue-router'
      import PopUp from '../../components/Pages/PopUp.vue';
      import {ref} from 'vue';

  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/navigation';  
  // import required modules
  import { Navigation } from 'swiper';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },

    data() {
    return {
    startyear:'',
    startmonth:'',
    startday:'',
    monthname:'',
    numberofdays:'',
    calcmonth:'',
    dayname:[],
    calcdatemonth:'',
    myDate:'',
    servicesCategory:[],
    category:'',
    services:[],
    service:[],
    serviceid:'',
    serviceCategory_id:'',
    isActive:[],
    hourOptions:[],
    nowyear:'',
    nowmonth:'',
    success:0,
    isAuth:false,
    isDisabled:[],
    swiper: null,
    nowday:'',
    lang:''
  
    };
  },

    setup() {
       
			
			


			
		return {
                modules: [Navigation],
			//	submit_shippingbox
				}

    },
    mounted() {
        window.scrollTo(0, 0);
        this.isLoogedin();
        this.getFirstDay();
        this.getdays();
        const route = useRoute();
        this.serviceid = route.params.id;
        this.serviceCategory_id=route.params.catid;
        this.get_service_info();
        this.get_hour_options();
        this.getlocale();
        if(this.success==1)
        {
            this.$router.push({
             name: 'MyAppointments'
            
        });
        }
       
    },

       
      
    methods:{

    getlocale()
        {
            this.lang=localStorage.getItem('locale');
        },

     onSwiper(swiper) {
      this.swiper = swiper;
    },
        isLoogedin(){
        let token=localStorage.getItem('token');
        if(token!=null)
        {
            this.isAuth=true;
            this.username=localStorage.getItem('username');
        }
        
        },

        get_hour_options()
        {
            for(let i=1 ;i<24;i++ )
            {
                // this.hourOptions.push(i+":00:00 - "+ i+":30:00");
                // this.hourOptions.push(i+":30:00 - "+(i+1)+":00:00");

                this.hourOptions.push(i+":00");
                this.hourOptions.push(i+":30");

            }
        },

        get_service_info()
       {
                this.servicesCategory= JSON.parse(localStorage.getItem("serviceCategory"));
                this.category=this.servicesCategory.find(cat =>cat.id==this.serviceCategory_id);
                this.services=this.category.services;
                console.log('services'+this.category);
                this.service=this.services.find(serve =>serve.id==this.serviceid);
              
        
       },


 getFirstDay()
    {
        const start = Date.now();
        const nowday=new Date(start);
        this.startyear = nowday.getFullYear();
        this.startmonth = nowday.getMonth()+1;
        this.nowmonth=this.startmonth;
        this.nowyear=this.startyear;
        this.startday=nowday.getDate();
        this.isActive[this.startday]=true;
        this.nowday= this.startday;
        this.swiper.slideTo(this.startday);
        this.getmonthname();

    },

    getmonthname()
    {
        switch(this.startmonth)
        {
            case(1): this.monthname='January';break;
            case(2): this.monthname='February'; break;
            case(3): this.monthname='March';break;
            case(4): this.monthname='April';break;
            case(5): this.monthname='May';break;
            case(6): this.monthname='June';break;
            case(7): this.monthname='July';break;
            case(8): this.monthname='August';break;
            case(9): this.monthname='September';break;
            case(10): this.monthname='October';break;
            case(11): this.monthname='November';break;
            case(12): this.monthname='December';break;
        }
    },

    previousmonth()
    {
       
     if(this.nowyear <= this.startyear && this.nowmonth < this.startmonth)
        { 
        this.startmonth--;
        if(this.startmonth==0)
        {
            this.startmonth=12;
            this.startyear--;
        }}
        if(this.startmonth!==this.nowmonth)
        this.swiper.slideTo(1);
        else
        this.swiper.slideTo(this.startday);
        this.getmonthname();
        this.getdays();

    },

    nextmonth(){
        this.startmonth++;
        if(this.startmonth==13)
        {
            this.startmonth=1;
            this.startyear++;
        }
        if(this.startmonth!==this.nowmonth)
        this.swiper.slideTo(1);
        else
        this.swiper.slideTo(this.startday);
        this.getmonthname();
        this.getdays();
    },

    getdays(){
        this.dayname=[];
        this.calcdatemonth=this.startmonth-1;
        this.numberofdays=30;

        if((this.calcdatemonth===3) || (this.calcdatemonth===5) || (this.calcdatemonth===8) || (this.calcdatemonth===10) )
        {
            this.numberofdays=30;
        }

        else  if((this.calcdatemonth===0) || (this.calcdatemonth===2) || (this.calcdatemonth===4) || (this.calcdatemonth===6) || (this.calcdatemonth===7) || (this.calcdatemonth===9) || (this.calcdatemonth===11) )
        {
            this.numberofdays=31;
        }
        else if(this.calcdatemonth===1)
       {
            if(this.startyear %4==0)
            {
                this.numberofdays=29;
            }
            else
                this.numberofdays=28;
       }
        this.getmonthname();

        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        for(let i=1 ;i<=this.numberofdays; i++)
        {
            const d = new Date( this.startyear,this.calcdatemonth,i);

            this.dayname[i] = days[d.getDay()];

            

        }


    }
,
    changeday(i)
    {
         this.startday=i;
         this.isActive=[];
         this.isActive[i]=true;
      
    },


    PostAppointment(){  
            
            const date=this.startyear+'/'+this.startmonth+'/'+this.startday;
            let time=document.getElementsByClassName('time')[0].value;
            let note=document.getElementsByClassName('note')[0].value;
            time=time+":00";
            console.log('time'+time);

			const formData = new FormData();
            formData.append('serviceId', this.serviceid);
            formData.append('date', date);
            formData.append('time', time);
            formData.append('note', note.toString());
		    axios.post('appointment',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
				}
			})
			.then(( response ) => {
                this.success=1;
                const locale='en'
                    this.$router.push({ path: `/my-appointments`,  params: { locale } })
			        console.log(response);
			} )
			.catch((error)=>{
                let lang=localStorage.getItem('locale')
                if(lang=='en')
                document.getElementById("response").innerHTML = "Appointment is not available, Please choose another time ";
                else
                document.getElementById("response").innerHTML = " هذا الموعد غير متاح الرجاء، اختيار موعد في وقت آخر  ";
	            document.getElementById('response').style.background="#edb2ad";
			    
                  setTimeout(() => {
                    document.getElementById("response").innerHTML ="";
                }, 5000);
                
                  console.log(error)
				})
               
			},

			 
   
	
	  
    

    }
       }
</script>




    
