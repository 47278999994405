<template>

    <div class="row home-padding">
		<div class="col-sm-12">
				
					<div class="inner-wrapper">
						<div class="row">	
							<div class=" col-lg-6 col-md-12 col-sm-8  ">
								<img class="img-signin" src="/images/nurse1.svg">							
							</div>
							<div class="col-lg-6 col-md-12 col-sm-4  hello">
								<h2>{{ $t('verify-code') }}</h2>
								
								<div class="col-sm-12">
							
									<div id="response"></div>
									<div class="water-p">{{ $t('insert-recieved-code') }} :</div>
                                    <!-- <div class="form-group input-div">
										<input type="text" required name="phone" class="form-control" id="exampleInputEmail1"  placeholder="">
									</div> -->
									
									<div class="form-group input-div ltr-element">
                    <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        :conditionalClass="['one', 'two', 'three', 'four','five','six']"
                        :placeholder="[]"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                      />
  </div>
  <button @click="clearInput()">Clear Input</button>
                                            <button class="btn" :disabled="!completed">
                                                    Continue
                                                </button>
									</div>
                                   
									<div class="primary-btn">    
										<button type="submit"  class="primary-btn-inside">{{ $t('submit') }}</button>    
      							   </div>
									

								
							</div>
							</div>
						</div>
						
					 <!-- /.inner-wrapper -->
				</div>
         </div>
      

       
</template>


<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { ref } from "vue";
import VOtpInput from 'vue3-otp-input';
  

export default {


name: 'VerifyCode',
data() {   
    return {
	phonenumber:'',
    code:''
    };
  },

  components:{
    VOtpInput,

  },
  
  methods:{


  },
  mounted(){
    window.scrollTo(0, 0);

    const completed = ref(false);
 
  },

 


     setup(){

        const otpInput = ref(null)

                const handleOnComplete = (value ) => {
                console.log('OTP completed: ', value);
                let confirmationResult=localStorage.getItem('confirmationResult');
                confirmationResult.confirm(value).then((result) => {
                    // User signed in successfully.
                    const user = result.user;
                    console.log(result);
                   
                });
                };

                const handleOnChange = (value) => {
                console.log('OTP changed: ', value);
                };

                const clearInput = () => {
                otpInput.value.clearInput()
                }

                return { handleOnComplete, handleOnChange, clearInput, otpInput };
                    },

	

   

};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>