<template >

    <account-page></account-page>
    
    </template>
    
    <script>
    import  AccountPage from '../../components/Profile/Account.vue';
    
    export default {
       name: "Account",
        components: { 
            AccountPage,
                        }, 
        };
    
    </script>