import { createStore } from 'vuex'

export default createStore({
  state: {
    prdNum:localStorage.getItem('prdNum')?localStorage.getItem('prdNum'):0
  },
  getters: {
    prdNum: function (state) {
      return `${state.prdNum}`
    }
  },
  mutations: {
    increment (state) {
      // mutate state
      state.prdNum++
    },

    decrement (state) {
      // mutate state
      state.prdNum--
    }
  },
  actions: {
  },
  modules: {
  }
})
