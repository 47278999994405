<template>
    <div class="blog-section-three position-relative home-padding">
				<div class="container dep-container">
                    <h4 class="dep-title dep-title-padding">
                        {{$t('Our Departments')}}
                    </h4>
				
					<div class="row  ">
                       
						<div class="col-lg-4   col-md-6 col-sm-12  " v-for="serviceCategory in servicesCategory"  :key="serviceCategory.id" data-aos="fade-up">     
                            <!-- <router-link :to="`/sub-services/1`" class="no-decoration-link-services img-txt hair-img-txt health-img ">  
                                    <h4> {{serviceCategory.categoryName}}</h4>
                            </router-link> -->

                            <div class="img-text-container">
                                <router-link :to="{ name: 'SubServices' ,params:{locale:this.$i18n.locale,id:serviceCategory.id} }" class="no-decoration-link-services ">  
                                    <img v-bind:src="serviceCategory.categoryImage" class="service_img" alt="Snow" style="width:80%;">
                                    <div class="centered">{{serviceCategory.categoryName}}</div>
                                </router-link>
                            </div>
                         </div>
                    </div>
				

            <!--  -->
             
                       
				</div>

        </div>
</template>

 
<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
import { useRouter } from 'vue-router';



export default {


data() {
return {
servicesCategory:[],
services:[],
choosencategory:0,
isActive:[]
 
};
},

setup() {
 
},
mounted() {
    window.scrollTo(0, 0);

this.getServiceCategories();
//this.changebackgroundimages();
},

methods:{
    getServiceCategories()
{
 this.servicesCategory= JSON.parse(localStorage.getItem("serviceCategory"));

 console.log("servicesCategory"+this.servicesCategory);
 

},

// changebackgroundimages(){
    
// }

// change_category(productCategory_id,index){
// this.choosencategory=productCategory_id;
// this.products=this.productsCategory.find(cat =>cat.id==productCategory_id).product;
// this.isActive=[];
// this.isActive[index]=true;

//}
}
}
</script>