<template>

</template>
<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
 
name: 'LogOut',
methods:{
 logout() {
        const router=useRouter();
        axios.post('auth/logout',{
		headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),
		}
		 })
     .then((response)=>{
		//this.profile=response.data.data
		console.log(response)
	 })
	 .catch((error)=>{
		console.log(error)
		console.log(localStorage.getItem('token'))
	 })
	 localStorage.removeItem('token');
	 localStorage.setItem('reloadLogin','1');

	 router.push('/');
    },
},

  created() {
    this.logout();
  }


    
   

};
</script>