<template >

    <Privacy-Policy-page></Privacy-Policy-page>
    
    </template>
    
    <script>
    import  PrivacyPolicyPage from '../../components/Profile/PrivacyPolicy.vue';
    
    export default {
       name: "PrivacyEdit",
        components: { 
            PrivacyPolicyPage,
        }, 
    };
    
    </script>