<template>
 <div class="blog-section-three position-relative home-padding">
				<div class="container palderma-friends-container">
					<div class="row gx-xxl-5">
						<div class="col-lg-3  col-md-4 col-sm-12 d-flex " v-for="PaldermaFriend in PaldermaFriends" data-aos="fade-up">     
                        

                            <div class="card card-gray">
                                <!-- <router-link :to="  {name: 'PaldermaFriend' ,params:{locale:this.$i18n.locale,id:PaldermaFriend.id}}" class="no-decoration-link"> -->
                                 <router-link :to="  {name: 'PaldermaFriend' ,params:{locale:this.$i18n.locale,id:PaldermaFriend.id}}" class="no-decoration-link">
                                 <div class="image-content">
                                      <span class="overlay">
                                             <div class="circle">
                                                <img v-bind:src="PaldermaFriend.paldermaFrindeImage" class="circle_img" >
                                             </div>
                                                 <div class="card-content">
                                                    <h4 class="name no-decoration-link" style="text-decoration: none !important;"> {{PaldermaFriend.paldermaFrindeName}}</h4>  
                                                    <p class="spec"> {{PaldermaFriend.specialization}}</p> 
                                                 </div>
                                        </span>
                                     </div>
                                 </router-link>
                             </div>
						
                     
                    </div>
              </div>
			</div>
		</div> <!-- /.blog-section-three -->
              
</template>


<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {

name: 'PaldermaFriends',
data() {
    return {
      PaldermaFriends:[]
	 
    };
  },
     setup(){
       

     },

     mounted() {
      window.scrollTo(0, 0);
    this.getPaldermaFriends();
  },
    
  methods:{
    getPaldermaFriends()
    {
         this.PaldermaFriends= JSON.parse(localStorage.getItem("paldermaFriends"));   
   
   },
   }
}



</script>