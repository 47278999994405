<template >
      
    <div class="fancy-feature-seventeen position-relative home-padding">
        <div class="container book-appointment-container">
            <div class="row align-items-center book-appointment-txt">
                <div class="col-xl-6 col-lg-5" data-aos="fade-out">
                    <transition name="fade">
                    <div class="title-style-three">
                        <h2 class="main-title">   {{$t('Your Health Comes')}} </h2>
                        <h2 class="main-title"> {{$t('First.')}}   </h2>
                        <p class="secondary-text">
                            {{$t('    The first center in Palestine for the treatment of all hair problems, treatment of genetic baldness, wrinkless, cosmetic surgery, laser hair removal, cold chemical peeling, tightening breasts in women, filler and Botox injection')}}
                        
                        </p>
                        <!-- With Medicare services you will receive the best medical treatment in your home. Our team of skilled medical professionals and aids ensure that you get the care you need and deserve. -->
                       <div class="">
                        <button class="primary-btn-no-border ">        
                            <router-link v-if="isAuth" class="no-decoration" :to="{ name: 'Services' }" role="button">{{$t('Book Appointment')}} </router-link>            
                            <router-link v-else class="no-decoration" :to="{ name: 'SignIn' }" role="button">{{$t('Book Appointment')}} </router-link>            

                        </button>
                    </div>
                    
                    </div> <!-- /.title-style-three -->
                </transition>
                </div>
                <div class="col-xl-6 col-lg-7 book-appointment-img" data-aos="fade-left" >
                  <img class="book-appointment-banner" src="/images/booking-nurse.svg"> 
                </div>
            </div>

           
        </div> <!-- /.container -->
     <!-- /.fancy-feature-seventeen -->

    <!-- red bar -->
    <div class="container">
    <div class="blog-section-three position-relative red-bar ">
				<div class="container">
					<div class="row gx-xxl-5">
						<div class="col-lg-4  col-md-6 col-sm-12 d-flex " data-aos="fade-up">
							<div class="red-col ">       
                                    <div class="row ">
                                        
                                        <div class="col-md-3  col-sm-5 col-5 offset-md-1 booking-img ">
                                            <img style="margin-top: 15px;" src="/images/time-icon.png">
                                        </div>
                                        <div class="col-md-6 col-sm-5 col-5 booking-txt">
                                            <strong> {{$t('24 hours services')}} </strong>
                                            <p>{{ $t('we-continue-to-provide-and-follow-up-our-services-throughout-the-day') }}</p>
                                        </div>
                                    </div>					            			
							</div>
						</div>
							<div class="col-lg-4  col-md-6 col-sm-12 d-flex " data-aos="fade-up">
							<div class=" red-col">
								
                                <div class="row">
                                        
                                        <div class="col-md-3  col-sm-5 col-5 offset-md-1 booking-img">
                                            <img style="margin-top: 15px;" src="/images/exper_icon.png">
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-6 booking-txt">
                                           <strong> {{$t('10 years of experience')}}  </strong>
                                            <p>{{ $t('ten-years-of-experience-we-put-at-your-service') }}</p>
                                        </div>
                                    </div>
                                </div>
						</div>
							<div class="col-lg-4  col-md-6  col-sm-12 d-flex " data-aos="fade-up">
							<div class=" red-col">	
                                <div class="row ">
                                        <div class="col-md-3  col-sm-5 col-5  offset-md-1 booking-img">
                                            <img style="margin-top: 15px;" src="/images/quality-icon.png">
                                        </div>
                                        <div class=" col-md-6  col-sm-6 col-6 booking-txt">
                                            <strong>{{ $t('modern-devices') }}</strong>
                                            <p>{{ $t('we-provide-the-latest-devices-and-tools-to-achieve-the-best-results') }}</p>
                                        </div>
                                    </div>
                        
							</div>
						</div>					
					</div>
				</div>
			</div> <!-- /.blog-section-three -->
        </div>
    </div>

</template>

<script>
export default {

name: 'BookAppointment',
data() {
    return {
    
    isAuth:false,
    
    };
  },

  mounted(){
        this.isLoogedin();
  },


methods:{
    isLoogedin(){
        let token=localStorage.getItem('token');
        if(token!=null)
        {
            this.isAuth=true;
            this.username=localStorage.getItem('username');
        }
        
        },

}
};

</script>
<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 5s ease-out;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>