<template>
        <book-appointment-section> </book-appointment-section>

        <health-requirements-section> </health-requirements-section>

       <departments-section></departments-section>

       <palderma-friends></palderma-friends>
       
       <products-section></products-section>

       <about-section></about-section>

       <find-stuff-section></find-stuff-section>

</template>


<script>

import BookAppointmentSection from '../../components/HomePage/BookAppointment.vue';
import HealthRequirementsSection from '../../components/HomePage/HealthRequirements.vue';
import DepartmentsSection from '../../components/HomePage/Departments.vue';
import AboutSection from '../../components/HomePage/About.vue';
import FindStuffSection from '../../components/HomePage/FindStuff.vue';
import PaldermaFriends from '../../components/HomePage/PaldermaFriends.vue';
import ProductsSection from '../../components/HomePage/Products.vue';





export default {
   name: "HomePage",
   setup(){
    if(localStorage.getItem('firstreload')==null)
    {
       
       localStorage.setItem('firstreload',1);
       location.reload(); 
    }
   },
	mounted() {


    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
},

    components: { 
        BookAppointmentSection ,    
        HealthRequirementsSection,
        DepartmentsSection,
        AboutSection,
        FindStuffSection,
        PaldermaFriends,
        ProductsSection
	 }, 
    };

</script>