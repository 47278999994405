import { createRouter, createWebHashHistory, RouterView } from 'vue-router'

import HomePage from '../views/pages/HomePage.vue'
import SignInPage from '../views/pages/SignIn.vue'
import SignUpPage from '../views/pages/SignUp.vue'
import PaldermaFriendsPage from '../views/pages/PaldermaFriends.vue'
import PaldermaFriendPage from '../views/pages/PaldermaFriend.vue'
import DoctorBookPage from '../views/pages/DoctorBook.vue'
import ServicesPage from '../views/pages/Services.vue'
import SubServicesPage from '../views/pages/SubServices.vue'
import BookServicePage from '../views/pages/BookService.vue'
import ProductsPage from '../views/pages/Products.vue'
import ProductPage from '../views/pages/Product.vue'
import ContactUsPage from '../views/pages/ContactUs.vue'
import AboutUsPage from '../views/pages/AboutUs.vue'
import PrivacyPage from '../views/pages/Privacy.vue'
import PrivacyEditPage from '../views/pages/PrivacyPolicy.vue'
import AccountPage from '../views/pages/Account.vue'
import MyAdressesPage from '../views/pages/MyAddresses.vue'
import MyOrdersPage from '../views/pages/MyOrders.vue'
import MyAppointmentsPage from '../views/pages/MyAppointments.vue'
import LogOutPage from '../views/pages/LogOut.vue'
import ShippingCartPage from '../views/pages/ShippingCart.vue'
import VerifyCodePage from '../views/pages/VerifyCode.vue'
import UpdateServiceBookPage from '../views/pages/BookServiceUpdate.vue'
import UpdatePasswordPage from '../views/pages/UpdatePassword.vue'

import i18n from "../i18n"



const routes = [
 
  {
  path: '/:locale?',
  component: RouterView,
  

  beforeEnter:(to,from,next)=>{
    const locale=to.params.locale;
    const supported_locales=process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    //if(! supported_locales.includes(locale)) return next('en')
    //if(i18n.locale !== locale){
      i18n.locale=locale;
   // }
    return next()
  },
  children:[
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },

  {
    path: 'signin',
    name: 'SignIn',
    component: SignInPage
  },
  {
    path: 'signup',
    name: 'SignUp',
    component: SignUpPage
  },
  {
    path: 'palderma-friends',
    name: 'PaldermaFriends',
    component: PaldermaFriendsPage
  },

  {
    path: 'palderma-friend/:id',
    name: 'PaldermaFriend',
    component: PaldermaFriendPage
  },

  {
    path: 'doctor-book/:id',
    name: 'DoctorBook',
    component:DoctorBookPage
  },

  {
    path: 'services',
    name: 'Services',
    component:ServicesPage
  },

  {
    path: 'sub-services/:id',
    name: 'SubServices',
    component:SubServicesPage
  },

  {
    path: 'service-book/:catid/:id',
    name: 'BookService',
    component:BookServicePage
  },

  {
    path: 'update-service-book/:id',
    name: 'UpdateBookService',
    component:UpdateServiceBookPage
  },


  {
    path: 'products',
    name: 'Products',
    component:ProductsPage
  },

  {
    path: 'product/:catid/:id',
    name: 'Product',
    component:ProductPage
  },

  {
    path: 'contact-us',
    name: 'ContactUs',
    component:ContactUsPage
  },

  {
    path: 'about-us',
    name: 'AboutUs',
    component:AboutUsPage
  },

  {
    path: 'privacy',
    name: 'Privacy',
    component:PrivacyPage
  },

  {
    path: 'privacy-policy',
    name: 'PrivacyPolicy',
    component:PrivacyEditPage
  },

  {
    path: 'account',
    name: 'Account',
    component:AccountPage
  },

  {
    path: 'my-addresses',
    name: 'MyAdresses',
    component:MyAdressesPage
  },

  {
    path: 'my-orders',
    name: 'MyOrders',
    component:MyOrdersPage
  },

  {
    path: 'my-appointments',
    name: 'MyAppointments',
    component:MyAppointmentsPage
  },

  {
    path: 'logout',
    name: 'LogOut',
    component:LogOutPage
  },

  {
    path: 'shipping-cart',
    name: 'ShippingCart',
    component:ShippingCartPage
  },

  {
    path: 'verify-code',
    name: 'VerifyCode',
    component:VerifyCodePage
  },

  {
    path: 'update-password',
    name: 'update-password',
    component:UpdatePasswordPage
  },


  
  ]

 
},




 


]



const router = createRouter({
  history: createWebHashHistory(),
  routes
})


// router.beforeEach((to,from,next)=>{
//   let language=to.params.lang;
//  // if(!language){
//       language='ar';
//   //}
  
//   i18n.locale=language;
//   next();
//   })
  
export default router
