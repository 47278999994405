<template >
    <div class="fancy-feature-seventeen  products-cont">
        <div class="container find-stuff-container">
            <div class="row align-items-center ">
                <div class="col-xl-6 col-sm-5  col-md-6 col-lg-6" data-aos="fade-right">
                    <div class="title-style-three  ">
                       <h3 class="stuff-txt">{{$t('Find the stuff you’ll')}}  </h3>
                       <h3 class="stuff-txt">{{$t('love.')}} </h3>
                       
                       <p class="stuff-p">
                        {{ $t('keep-in-touch-and-download-the-centers-mobile-application-to-follow-up-on-more-offers-and-services') }}                    </p>
                     
                       <div class="row ">
                            <!-- <div class="col-lg-4 col-md-6 c0l-sm-12">
                                <button class="btn btn-dark icons-row">
                                    <img class="store_icon" src="/images/app_store_img.png">
                                </button>
                            </div> -->

                            <div class="col-lg-4  col-md-6 c0l-sm-12">
                                <button class="btn btn-dark icons-row" v-on:click="openApp()">
                                    <img class="store_icon"  src="/images/google_play_store.png">
                                </button>
                            </div>
                        </div>

                       
                            
                       
                    </div> <!-- /.title-style-three -->
                </div>
               
                <div class="col-xl-6 col-sm-7 col-md-6 col-lg-6  " data-aos="fade-left" >
                    <div class="container">
                  <!-- <img class="book-appointment-banner" src="/images/booking-nurse.svg">  -->  
                 
                      <img class="mobile-home-img"   src="/images/mobile_home.svg">
	
						</div>					
				</div>

                
				
			</div> <!-- /.blog-section-three -->
            <div class="col-xl-12 col-sm-12 col-md-12 col-lg-12  get-started" data-aos="fade-left" >
                <div class="row"> 
                <div class="container col-lg-8 col-md-6 col-sm-6 ">
                         <h4> {{$t('Ready to get started ?')}}</h4>
                         <p>{{ $t('take-advantage-of-the-best-medical-expertise-and-the-best-equipment-to-get-the-best-result') }}</p>
					</div>					
				    
                    <div class="col-lg-4 col-md-6 col-sm-6 create-now-div">
                        <button class="secondary-btn find-stuff-btn">          
                             <router-link  v-if="isAuth" class="secondary-btn-inside" :to="{ name: 'Services' }" role="button">{{$t('Create Appointment Now!')}}</router-link>           
                             <router-link  v-else class="secondary-btn-inside" :to="{ name: 'SignIn' }" role="button">{{$t('Create Appointment Now!')}}</router-link>            
                        </button>
                    </div>
                </div>
         </div>
        </div>   
           
     </div> <!-- /.container -->
 </template>

 <script>
export default {

name: 'FindStuff',

data() {
    return {
    
    isAuth:false,
    
    };
  },

  mounted(){
        this.isLoogedin();
  },


methods:{
openApp()
{
  
    window.open('https://play.google.com/store/apps/details?id=com.palderma.android&pli=1');
   
},

    isLoogedin(){
        let token=localStorage.getItem('token');
        if(token!=null)
        {
            this.isAuth=true;
            this.username=localStorage.getItem('username');
        }
        
        },

}
}

</script>