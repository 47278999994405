<template >

    <Product-page></Product-page>
    
    </template>
    
    <script>
    import  ProductPage from '../../components/Pages/Product.vue';
    
    export default {
       name: "Product",
        components: { 
           ProductPage,
                        }, 
        };
    
    </script>