<template>
    <div class="fancy-feature-seventeen position-relative home-padding" data-aos="fade-left">
        <div class="container book-appointment-container">
            <div class="row align-items-center book-appointment-txt">
                <div class="col-xl-6 col-lg-5" data-aos="fade-right">
                    <!-- <img class="book-appointment-banner" src="/images/booking-nurse.svg"> -->
                    <div class="friend-background">
                        <div class="friend-info">
                            <img v-bind:src="friend.paldermaFrindeImage" class="friend_circle_img">
                            <div class="card-content">
                                <h3 class="name no-decoration-link friend-name" style="text-decoration: none !important;">
                                    {{ friend.paldermaFrindeName }}</h3>
                                <p class="spec">{{ friend.specialization }}</p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div> <!-- /.title-style-three -->

                <div class="col-xl-6 col-lg-7 friend-container" >

                    <div class="title-style-three   book-doctor-row">
                        <h6 class="margin-bottom-sec">{{ $t('About Doctor') }} </h6>
                        <p class="secondary-text margin-bottom-sec">{{ friend.paldermaFrindeDescription }} </p>

                        <h6 class="margin-bottom-sec">{{$t('Working time')}}  </h6>
                        <p class="secondary-text margin-bottom-sec" v-if="friend.fromday !== null">{{ friend.fromday }} -
                            {{ friend.today }} ({{ friend.fromtime }} - {{ friend.totime }} ) </p>
                        <p class="secondary-text margin-bottom-sec" v-if="friend.fromday == null"> ({{ friend.fromtime }} -
                            {{ friend.totime }} ) </p>

                        <h6 class="margin-bottom-sec"> {{ $t('contact-doctor-0') }} </h6>
                        <p class="secondary-text margin-bottom-sec">

                        <div class="row doctor-info ">
                            <div class="col-lg-1 col-md-1 col-sm-6">
                                <img src="/images/location-icon.png">
                            </div>
                            <div class="col-lg-8 col-md-8 col-sm-6 secondary-text doctor-info">
                                {{ friend.paldermaFrindeAddress }}
                            </div>
                        </div>

                        <div class="row doctor-info ">
                            <div class="col-lg-1 col-md-1 col-sm-6">
                                <img src="/images/whatsapp-icon.png">
                            </div>

                            <div class="col-lg-8 col-md-8 col-sm-6 secondary-text text-lg doctor-info">
                                {{ friend.phone }}
                            </div>

                        </div>

                        <div class="row doctor-info ">
                            <div class="col-lg-1 col-md-1 col-sm-6 ">
                                <img src="/images/email-icon.png">
                            </div>

                            <div class="col-lg-8 col-md-8 col-sm-6 secondary-text text-lg doctor-info">
                                {{ friend.email }}
                            </div>

                        </div>

                        </p>

                        <div class="">
                            <button class="primary-btn-no-border border-radius-btn ">
                                <!-- <router-link  class="no-decoration" :to="  {name: 'DoctorBook' ,params:{locale:this.$i18n.locale,id:friend.id}}"  role="button">Book Appointment</router-link>             -->
                                <router-link :to="{ name: 'DoctorBook', params: { locale: this.$i18n.locale, id: friendid } }"
                                    class="no-decoration">{{$t('Book Appointment')}}</router-link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> <!-- /.container -->
</template>
   
   
<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router'

export default {

    name: 'PaldermaFriend',
    data() {
        return {
            friendid: this.$route.params.id,
            PaldermaFriends: [],
            friend: []

        };
    },
    setup() {


    },
    mounted() {
        window.scrollTo(0, 0);
        const route = useRoute();
        this.friendid = route.params.id;
        this.get_friend_info();
    },

    methods: {
        get_friend_info() {
            this.PaldermaFriends = JSON.parse(localStorage.getItem("paldermaFriends"));
            this.friend = this.PaldermaFriends.find(fr => fr.id == this.friendid);
            console.log('friend' + this.friend);
        },
    }
}
</script>