<template>
 <!-- <form  action="#" @submit.prevent="PostAppointment()" id="submit_appointment">        -->

    <div class="fancy-feature-seventeen position-relative home-padding" data-aos="fade-left">
           <div class="container book-appointment-container">
               <div class="row align-items-center book-appointment-txt">
                   <div class="col-xl-6 col-lg-5 book-image-cont" >
                    <div class="row">
                        <h4 class="dep-title title-home">{{ $t('updateAppointment') }} </h4>

        <div class="col-lg-11 col-md-11 col-sm-11 col-11 container appointment-card" v-for= "(appointment ,index) in appointments" :key="appointment.id">
            <div class="my-appointments">     
              <div class="container">
                <div class="row">
                    <div class="col-2">
                    </div>

                    <div class="col-3">
                         <h6 class="appointment-name"> {{appointment.userName}} </h6>  
                    </div>

                    <div class="col-5">
                        <div>
                            {{appointment.date}}
                        </div>
                    </div>
                    
                  
                    
                </div>

                <div class="row">
                    <div class="col-2">
                        <img src="/images/my-appointments-logo.png">
                    </div>
                    <div class="col-10">
                        <div>{{appointment.serviceCategory}}</div>
                        <div>{{appointment.serviceName}} at {{appointment.time}} </div>
                    </div>
                </div>

                <div class="row appointment-row">
                    <div class="col-2">
        
                    </div>
                    <div class="col-2">
                       <img src="/images/appointments-img.png">
                    </div>

                    <div class="col-4">
                       <h6 class="dr-name">Dr. Abdulla</h6>
                    </div>

                    <div class="col-4">
                        <div v-if="appointment.status==0" class="order-pending appointment-status">
                            {{ $t('pending') }}

                         </div> 

                         <div v-if="appointment.status==1" class="order-accepted appointment-status">    
                            {{ $t('accepted') }}

                         </div> 

                         <div v-if="appointment.status==2" class="order-completed appointment-status">    
                            {{ $t('rejected') }}
                         </div> 
                      </div>
                    </div>
                  </div>
                 </div>
              </div>
            </div>

                                
                      </div>
                       
                 


                <div class="col-xl-6 col-lg-7 friend-container" data-aos="fade-left" >
                    
                    <div class="title-style-three   book-doctor-row">
                       
                      
                        <div v-if="lang=='en'" class="month-year ">
                            <h5>
                            <button @click="previousmonth()" class="month-pre"> <img class="arrows-icon" src="/images/chevron.png"> </button>
                           <span class="month-year-input">{{monthname}} - {{startyear}}</span> 
                            <button @click="nextmonth()" class="month-next"><img class="arrows-icon" src="/images/right.png"> </button>
                        </h5>   
                        </div>

                        <div v-else class="month-year ">
                            <h5>
                             <button @click="nextmonth()" class="month-next"><img class="arrows-icon" src="/images/right.png"> </button>
                                  <span class="month-year-input">{{monthname}} - {{startyear}}</span> 
                            <button @click="previousmonth()" class="month-pre"> <img class="arrows-icon" src="/images/chevron.png"> </button>
                        </h5>   
                        </div>
                        <div class="ltr-element">
                        <swiper  :modules="modules" :slides-per-view="6"   @swiper="onSwiper"
                            :space-between="10" class="mySwiper">
                            <swiper-slide v-for="(dname ,index) in dayname" :key="index">
                                <div class="slide-content">
                                    <div class="slide-wrapper">      
                                        <!--- -  -->
                                        <div round color="light" v-bind:class="{ activedaybtn: isActive[index], disabledSlide:(((startyear==nowyear)&&(nowmonth==startmonth)&&(index<nowday))) }" class="scroll-click" v-if="index !==0" v-on:click="changeday(index)">
                                                <ion-label class="rest-id" selected>{{index}} <br>{{dname}}</ion-label>
                                        </div>
                                    </div>
                                </div>   
                            </swiper-slide>
                        </swiper>
                    </div>
                   </div>

                   <div class="title-style-three  book-doctor-row">
                        <h6>{{ $t('appointment-time') }}</h6>
                        <!-- <input class="book-input form-control" type="text" placeholder="12:00  12:30"> -->
                  <select required name="time" id="time" class="time book-input form-control">
                    <option class="hour-option" v-for=" hourOption in hourOptions" :key="hourOption.id" v-bind:value=hourOption>{{ hourOption }}</option>
                  </select>
                    </div>

                    <div class="title-style-three   book-doctor-row">
                        <h6> {{ $t('notes') }}</h6>
                        <textarea class="note book-input-text form-control"></textarea>
                    </div>
                    <div id="response"></div>
                    <button v-if="isAuth" v-on:click="UpdateAppointment()" class="primary-btn-no-border border-radius-btn no-decoration">        
                        {{ $t('updateAppointment') }}   
                    </button>
                    <router-link :to="{ name: 'SignIn' ,params:{locale:this.$i18n.locale}}" class="no-decoration"  v-else>
                            <button   class="primary-btn-no-border border-radius-btn no-decoration" >        
                                {{ $t('updateAppointment') }}  
                            </button>
                   </router-link>
               </div>


              
					        
          
        </div>
              </div>
           
           </div> <!-- /.container -->    
        <!-- </form> -->
   </template>
      
      
      <script>
      import { logicalExpression } from '@babel/types';
      import axios from 'axios';
      import { Swiper, SwiperSlide } from 'swiper/vue';
      import { useRouter,useRoute } from 'vue-router'
      import PopUp from './PopUp.vue';
      import {ref} from 'vue';

  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/navigation';  
  // import required modules
  import { Navigation } from 'swiper';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },

    data() {
    return {
    startyear:'',
    startmonth:'',
    startday:'',
    monthname:'',
    numberofdays:'',
    calcmonth:'',
    dayname:[],
    calcdatemonth:'',
    myDate:'',
    servicesCategory:[],
    category:'',
    services:[],
    service:[],
    serviceid:'',
    serviceCategory_id:'',
    isActive:[],
    hourOptions:[],
    nowyear:'',
    nowmonth:'',
    success:0,
    isAuth:false,
    isDisabled:[],
    swiper: null,
    nowday:'',
    lang:'',
    serviceCategory:'',
    srv:[],
    appointments:[],
    appointment_id:''
    };
  },

    setup() {
			
		return {
                modules: [Navigation],
				}

    },
    mounted() {
        window.scrollTo(0, 0);
        this.isLoogedin();
        this.getFirstDay();
        this.getdays();
        this.getAppointments();
        const route = useRoute();
        this.serviceid = route.params.servid;
        this.appointment_id=route.params.id;
        this.serviceCategory=route.params.servcategory;
      //  this.serviceCategory_id=route.params.catid;
        this.get_service_info();
        this.get_hour_options();
        this.getlocale();
        if(this.success==1)
        {
            this.$router.push({
             name: 'MyAppointments'
            
        });
        }
       
    },

       
      
    methods:{
        getAppointments()
    
	{
         let locale=localStorage.getItem('locale')	
        axios.get('appointment',{
        headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),
           "Accept-Language": locale
				
        }})
			.then((response)=>{
				
				this.appointments=response.data.content.appointment;
				console.log(this.appointments);
				

			})
			.catch((error)=>{
				console.log(error)
			})
		},
    getlocale()
        {
            this.lang=localStorage.getItem('locale');
        },

     onSwiper(swiper) {
      this.swiper = swiper;
    },
        isLoogedin(){
        let token=localStorage.getItem('token');
        if(token!=null)
        {
            this.isAuth=true;
            this.username=localStorage.getItem('username');
        }
        
        },

        get_hour_options()
        {
            for(let i=1 ;i<24;i++ )
            {
                // this.hourOptions.push(i+":00:00 - "+ i+":30:00");
                // this.hourOptions.push(i+":30:00 - "+(i+1)+":00:00");

                this.hourOptions.push(i+":00");
                this.hourOptions.push(i+":30");

            }
        },

        get_service_info()
       {
                this.servicesCategory= JSON.parse(localStorage.getItem("serviceCategory"));
                this.service=this.servicesCategory.forEach(element=>{
                    this.srv=element.services.find(serve =>serve.id==this.serviceid);
                    

                })
                // this.services=this.category.services;
                // console.log('services'+this.category);
                //this.service=this.services.find(serve =>serve.id==this.serviceid);
                console.log('service'+this.srv)
              
        
       },


 getFirstDay()
    {
        const start = Date.now();
        const nowday=new Date(start);
        this.startyear = nowday.getFullYear();
        this.startmonth = nowday.getMonth()+1;
        this.nowmonth=this.startmonth;
        this.nowyear=this.startyear;
        this.startday=nowday.getDate();
        this.isActive[this.startday]=true;
        this.nowday= this.startday;
        this.swiper.slideTo(this.startday);
        this.getmonthname();

    },

    getmonthname()
    {
        switch(this.startmonth)
        {
            case(1): this.monthname='January';break;
            case(2): this.monthname='February'; break;
            case(3): this.monthname='March';break;
            case(4): this.monthname='April';break;
            case(5): this.monthname='May';break;
            case(6): this.monthname='June';break;
            case(7): this.monthname='July';break;
            case(8): this.monthname='August';break;
            case(9): this.monthname='September';break;
            case(10): this.monthname='October';break;
            case(11): this.monthname='November';break;
            case(12): this.monthname='December';break;
        }
    },

    previousmonth()
    {
       
     if(this.nowyear <= this.startyear && this.nowmonth < this.startmonth)
        { 
        this.startmonth--;
        if(this.startmonth==0)
        {
            this.startmonth=12;
            this.startyear--;
        }}
        if(this.startmonth!==this.nowmonth)
        this.swiper.slideTo(1);
        else
        this.swiper.slideTo(this.startday);
        this.getmonthname();
        this.getdays();

    },

    nextmonth(){
        this.startmonth++;
        if(this.startmonth==13)
        {
            this.startmonth=1;
            this.startyear++;
        }
        if(this.startmonth!==this.nowmonth)
        this.swiper.slideTo(1);
        else
        this.swiper.slideTo(this.startday);
        this.getmonthname();
        this.getdays();
    },

    getdays(){
        this.dayname=[];
        this.calcdatemonth=this.startmonth-1;
        this.numberofdays=30;

        if((this.calcdatemonth===3) || (this.calcdatemonth===5) || (this.calcdatemonth===8) || (this.calcdatemonth===10) )
        {
            this.numberofdays=30;
        }

        else  if((this.calcdatemonth===0) || (this.calcdatemonth===2) || (this.calcdatemonth===4) || (this.calcdatemonth===6) || (this.calcdatemonth===7) || (this.calcdatemonth===9) || (this.calcdatemonth===11) )
        {
            this.numberofdays=31;
        }
        else if(this.calcdatemonth===1)
       {
            if(this.startyear %4==0)
            {
                this.numberofdays=29;
            }
            else
                this.numberofdays=28;
       }
        this.getmonthname();

        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        for(let i=1 ;i<=this.numberofdays; i++)
        {
            const d = new Date( this.startyear,this.calcdatemonth,i);

            this.dayname[i] = days[d.getDay()];

            

        }


    }
,
    changeday(i)
    {
         this.startday=i;
         this.isActive=[];
         this.isActive[i]=true;
      
    },


    UpdateAppointment(){  
            
            const date=this.startyear+'/'+this.startmonth+'/'+this.startday;
            let time=document.getElementsByClassName('time')[0].value;
            let note=document.getElementsByClassName('note')[0].value;
            time=time+":00";
            console.log('time'+time);

			const formData = new FormData();
            formData.append('appointment_id',  this.appointment_id);
            formData.append('date', date);
            // formData.append('time', time);
            formData.append('note', note.toString());
		    axios.post('appointment/update',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
				}
			})
			.then(( response ) => {
                this.success=1;
                const locale='en'
                    this.$router.push({ path: `/my-appointments`,  params: { locale } })
			        console.log(response);
			} )
			.catch((error)=>{
                let lang=localStorage.getItem('locale')
                if(lang=='en')
                document.getElementById("response").innerHTML = "Appointment is not available, Please choose another time ";
                else
                document.getElementById("response").innerHTML = " هذا الموعد غير متاح الرجاء، اختيار موعد في وقت آخر  ";
	            document.getElementById('response').style.background="#edb2ad";
			    
                  setTimeout(() => {
                    document.getElementById("response").innerHTML ="";
                }, 5000);
                
                  console.log(error)
				})
               
			},

			 
   
	
	  
    

    }
       }
</script>




    
