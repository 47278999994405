<template >

    <update-service-book-page></update-service-book-page>
    
    </template>
    
    <script>
    import  UpdateServiceBookPage from '../../components/Pages/BookServiceUpdate.vue';
    
    export default {
       name: "UpdateServiceBook",
        components: { 
            UpdateServiceBookPage,
                        }, 
        };
    
    </script>