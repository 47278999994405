<template>
    <div class="fancy-feature-seventeen position-relative home-padding">
           <div class="container product-container-main">
               <div class="row align-items-center book-appointment-txt">
                   <div class="col-xl-6 col-lg-5 product-img-container" data-aos="fade-right ">
                       <!-- <img class="book-appointment-banner" src="/images/booking-nurse.svg"> -->
                               <img class="product-banner-image" v-bind:src="product.productImage"  >
                                
                      </div>
                       
                 
                <div class="col-xl-6 col-lg-7 product-info-container" data-aos="fade-left" >
                    
                    <div class="title-style-three  book-doctor-row">
                        <h4>{{product.productName}}</h4>
                        <div class="row srv-details">
                            <div class="col-lg-3 col-md-3 col-sm-3  col-6 book-srv-title">
                                {{category.categoryName}}
                            </div>
                          
                        </div>
                        <p class="secondary-text">
                            {{ product.productDescription }}      
                        </p>                  
                       
                      
                   </div>

                   <div class="col-lg-12 col-md-12 col-sm-12">
                    

                           <!-- <div class="quant-btn col-lg-1 col-md-1 col-sm-1 ">+</div> -->
                           <!-- <input type="number" class=" form-control" v-model="itemsCount" /> -->

                            <!-- <div class="quant-btn col-lg-1 col-md-1 col-sm-1  ">-</div> -->

                     <!-- <div class="  text-lg  col-lg-12 col-md-12 col-sm-12"> -->
                        <div class="row prod-cart-details ltr-element">
                      
                            <div class="col-lg-1 col-md-3 col-sm-3 col-3  quant-btn quant-btn-border" @click="minquant()">
                                 <span class="quant-mark"> - </span>  
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-3 col-3 quant-btn-input quant-btn-div">
                                <input type="text" class="form-control col-lg-5 quant-btn-input" v-model="itemsCount" />
                            </div>
                            <div class="col-lg-1 col-md-3 col-sm-3 col-3 quant-btn quant-btn-border-right " @click=addquant()>
                                <span class="quant-mark"> + </span>  
                            </div>
                        
                            <div class="col-lg-3 col-md-3 col-sm-3 offset-2 prd-price book-srv-price ">
                              {{price}} ILS
                            </div>
                        </div>
                        </div>
                        
                   
                    <!-- </div> -->

                    

                    <button v-if="isAuth"  class="primary-btn-no-border border-radius-btn no-decoration" v-on:click="addToCart()">        
                            {{ $t('addToCart') }}
                    </button>
                    <router-link :to="{ name: 'SignIn' ,params:{locale:this.$i18n.locale}}" class="no-decoration"  v-else>
                            <button   class="primary-btn-no-border border-radius-btn no-decoration" >        
                                    {{ $t('addToCart') }}
                            </button>
                   </router-link>
               </div>
           </div>
              </div>
           </div> <!-- /.container -->    
   </template>
      
      
      <script>
      import { logicalExpression } from '@babel/types';
      import axios from 'axios';
      import { useRoute } from 'vue-router'
  
  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/navigation';  
  // import required modules
  import { Navigation } from 'swiper';

  export default {
    components: {
     
    },

    data() {
    return {
        itemsCount:1,
        prodid:1,
        productsCategory:[],
        productcat:[],
        productCategory_id:'',
        product:[],
        category:[],
        price:0,
        quantities:[],
        cartProducts:[],
        isAuth:false,
      //  cartQuantities:[],
        first:true,
       
       
    };
  },

    setup() {
      
      return {
        modules: [Navigation],
      };
    },
   
    mounted() {
        window.scrollTo(0, 0);
        const route = useRoute();
        this.prodid = route.params.id;    
        this.productCategory_id=route.params.catid;   
        this.isLoogedin();
        this.get_product_info();
     
        
    },
      
    methods:{
        get_product_info()
       {
       
                this.productsCategory= JSON.parse(localStorage.getItem("productCategory"));
                this.category=this.productsCategory.find(cat =>cat.id==this.productCategory_id);
                this.products=this.category.product;
                this.product=this.products.find(prod =>prod.id==this.prodid);
                this.productprice=this.product.price;
                this.price=this.productprice;
                console.log('product'+this.product);
             
        
       },
        addquant()
        {
            this.itemsCount++; 
            this.price+=this.productprice;
        },
        minquant()
        {

            if(this.itemsCount >1)
            {
                this.itemsCount--; 
                this.price-=this.productprice;
            }
           
        },

      isLoogedin(){
        let token=localStorage.getItem('token');
        if(token)
        {
            this.isAuth=true;
            this.username=localStorage.getItem('username');
        }
        
        },

        addToCart(){
            this.cartProducts=[];
            this.products=JSON.parse(localStorage.getItem("products") || null);
        //    this.quantities=JSON.parse(localStorage.getItem("quantities") || null);
            if(this.first==true){
                if( JSON.parse(localStorage.getItem("products"))!==""){
                    JSON.parse(localStorage.getItem("products")).forEach(element => {
                        this.cartProducts.push(element);
                    });
                }
               
            // JSON.parse(localStorage.getItem("quantities") ).forEach(element=>{
            //     this.cartQuantities.push(element);
            // });
            this.first==false;

        }
        let prdtotal=this.itemsCount*this.product.price;
                this.cartProducts.push(
                    {prdName:this.product.productName,
                     prodId:this.prodid,
                     prdCount:this.itemsCount,
                     prdPrice:this.product.price,
                     prdtotal:prdtotal,
                     prodImg:this.product.productImage
                    });
              //  this.cartQuantities.push(this.itemsCount);
                let productsParsed = JSON.stringify(this.cartProducts);
               // let quantParsed = JSON.stringify(this.cartQuantities);
                localStorage.setItem('products', productsParsed);
              //  localStorage.setItem('quantities', quantParsed);
               
                localStorage.setItem('prdNum',this.cartProducts.length)
            //   location.reload();
              this.$store.commit('increment'); 
            

        }
       },
       

    }
;
</script>