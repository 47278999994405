<template >
    <div class="fancy-feature-seventeen position-relative  ">
        <div class="container book-appointment-container">
            <div class="row align-items-center book-appointment-txt main-req">
                <div class="col-xl-6 col-md-6 col-lg-5" data-aos="fade-right">
                    <div class="title-style-three ">
                        <h4 >{{$t('Your health requirements are')}}      </h4>
                       <h4>{{$t('our first focus.')}}    </h4> 
                    </div> <!-- /.title-style-three -->
                </div>
                <div class="col-xl-6 col-md-6 col-lg-7" data-aos="fade-left" >
                   <p class="secondary-text">
                    {{$t('Our team of skilled medical professionals and aids ensure that you get the care you need and deserve.')}}
                   </p> 
                </div>
            </div>          
        </div> <!-- /.container -->
        </div>

        <!-- four banners -->
        
    <div class="blog-section-three position-relative ">
				<div class="container">
					<div class="row gx-xxl-5">
						<div class="col-lg-3  col-md-6 col-sm-12 col-12  d-flex "  data-aos="fade-up">     
                           <div class="row health-img">
                                <img  v-if="lang=='en'" src="/images/customer-care.png">
                                <img  v-if="lang=='ar'" src="/images/customer-care-ar.png">
                            </div>
						</div>
                        
                        <div class="col-lg-3  col-md-6 col-sm-12 col-12  d-flex " data-aos="fade-up">     
                            <div class="row health-img">
                                  <img v-if="lang=='en'" src="/images/chamber.png">
                                  <img  v-if="lang=='ar'" src="/images/Chamber-ar.png">
                            </div>
						</div>

                        <div class="col-lg-3  col-md-6 col-sm-12 col-12  d-flex " data-aos="fade-up">     
                            <div class="row health-img">
                                 <img v-if="lang=='en'" src="/images/online-spercialist.png">
                                 <img  v-if="lang=='ar'" src="/images/specialist1-ar.png">
                             </div>
						</div>
                        
                        <div class="col-lg-3  col-md-6 col-sm-12 col-12 d-flex " data-aos="fade-up">     
                            <div class="row health-img">
                                 <img  v-if="lang=='en'" src="/images/security.png">
                                 <img  v-if="lang=='ar'" src="/images/Security-ar.png">
                             </div>
						</div>
                        </div>
				</div>
		</div> <!-- /.blog-section-three -->
       
    
        </template>




<script>
    export default {
       
    data() {
        return {
                 lang:''

          };
        },

     setup() {

        },
     mounted() {
        this.getlocale();
        },

        methods:{



     getlocale()
        {
        this.lang=localStorage.getItem('locale');
     
}
}
}	</script>