<template >

    <My-Orders-page></My-Orders-page>
    
    </template>
    
    <script>
    import  MyOrdersPage from '../../components/Profile/MyOrders.vue';
    
    export default {
       name: "MyOrders",
        components: { 
            MyOrdersPage,
                        }, 
        };
    
    </script>