<template >

    <Services-page></Services-page>
    
    </template>
    
    <script>
    import  ServicesPage from '../../components/Pages/Services.vue';
    
    export default {
       name: "Services",
        components: { 
            ServicesPage,
                        }, 
        };
    
    </script>