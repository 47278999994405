<template >

    <Verify-Code-page></Verify-Code-page>
    
    </template>
    
    <script>
    import  VerifyCodePage from '../../components/Pages/VerifyCode.vue';
    
    export default {
       name: "VerifyCode",
        components: { 
            VerifyCodePage,
                        }, 
        };
    
    </script>