/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import "../public/frontend/css/global.css"
import "../public/frontend/css/animate.min.css"
import store from './store'
import i18n from './i18n'
import { initializeApp } from "firebase/app";
import firebase from "firebase/app";


/* code from our Firebase console */
const firebaseConfig = {
  apiKey: "AIzaSyDCAh5y6GRyymuC9ijLxEwYwLOknwDABEU",
  authDomain: "palderma-da43d.firebaseapp.com",
  projectId: "palderma-da43d",
  storageBucket: "palderma-da43d.appspot.com",
  messagingSenderId: "698749914291",
  appId: "1:698749914291:web:2ff174ba9e7ac2b33df4c6",
  measurementId: "G-TS84XSQY5Z"
};
axios.defaults.baseURL= "https://palderma.com/Palderma/api/"



initializeApp(firebaseConfig);

createApp(App).use(i18n).use(store).use(router).mount('#app')
