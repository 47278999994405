<template>
    <div class="container home-padding">
    <div class="row">

        <div class="col-lg-3 col-md-5 col-sm-12 col-12 side-bar-border">
            <side-bar-section> </side-bar-section>

        </div>
        <div class="col-lg-9 col-md-7 col-sm-12 col-12 appointemnt-container">
            <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12  appointment-card" v-for= "(appointment ,index) in appointments" :key="appointment.id">
            <div class="my-appointments">     
              <div class="container">
                <div class="row">
                    <div class="col-2">
                    </div>

                    <div class="col-3">
                         <h6 class="appointment-name"> {{appointment.userName}} </h6>  
                    </div>

                    <div class="col-5">
                        <div>
                            {{appointment.date}}
                        </div>
                    </div>
                    
                    <div class="col-2" >
                        <div class="dropdown" >
                            <span v-on:click="dropdown(index);" > 
                                <img class="three-points dropdown-toggle"  src="/images/three-points-btn.png">
                            </span>
                        <div class="dropdown-content">
                           
                               <router-link :to= "{ name: 'UpdateBookService' ,params:{id:appointment.id} }"    class="no-decoration-link">
                                <div class="row menu-choice menu-choice-border" >
                               
                                <div class="col-4">
                                   <img src="/images/edit-icon.png">
                                </div>
                                <div class="col-8">
                                   {{ $t('edit') }}
                                </div>
                            </div>
                            </router-link>
                               
                            

                             <div class="row menu-choice" v-on:click="delete_apponitment(appointment.id)">
                                <div class="col-4">
                                   <img src="/images/delete-icon.png"  >
                                </div>
                                <div class="col-8">
                                   {{ $t('delete') }}
                                </div>

                                
                             </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-2">
                        <img src="/images/my-appointments-logo.png">
                    </div>
                    <div class="col-10">
                        <div>{{appointment.serviceCategory}}</div>
                        <div>{{appointment.serviceName}} at {{appointment.time}} </div>
                    </div>
                </div>

                <div class="row appointment-row">
                    <div class="col-2">
        
                    </div>
                    <div class="col-2">
                       <img src="/images/appointments-img.png">
                    </div>

                    <div class="col-4">
                       <h6 class="dr-name">Dr. Abdulla</h6>
                    </div>

                    <div class="col-4">
                        <div v-if="appointment.status==0" class="order-pending appointment-status">
                            {{ $t('pending') }}

                         </div> 

                         <div v-if="appointment.status==1" class="order-accepted appointment-status">    
                            {{ $t('accepted') }}

                         </div> 

                         <div v-if="appointment.status==2" class="order-completed appointment-status">    
                            {{ $t('rejected') }}
                         </div> 
                    </div>
                </div>


              </div>
            </div>
            
        </div>

        
           </div>
         </div>
     </div>
 </div>
           
    
</template>


<script>

import SideBarSection from '../../components/Profile/SideBar.vue';
import axios from 'axios';

export default {
name: "MyAppointments",
mounted() {
    window.scrollTo(0, 0);
    this.getAppointments();

},

components: { 
    SideBarSection ,    
   
 }, 
 setup(){
    localStorage.setItem('sidebarActive','1');

},
 data() {
    return {
        appointments:[]
    };
  },

 methods:{
    
    getAppointments()
    
	{
         let locale=localStorage.getItem('locale')	
        axios.get('appointment',{
        headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),
           "Accept-Language": locale
				
        }})
			.then((response)=>{
				
				this.appointments=response.data.content.appointment;
				console.log(this.appointments);
				

			})
			.catch((error)=>{
				console.log(error)
			})
		},
		
    delete_apponitment(id){
     axios.delete('appointment?appointment_id='+id,{
        headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
        }})
			.then((response)=>{
				
				
				location.reload();

			})
			.catch((error)=>{
				console.log(error)
			})
        },
        
    dropdown(i){
        if(document.getElementsByClassName('dropdown-content')[i].style.display!=='block'){
            document.getElementsByClassName('dropdown')[i].style.display='block';
            document.getElementsByClassName('dropdown-content')[i].style.display='block';
        }

        else 
        {
            document.getElementsByClassName('dropdown-content')[i].style.display='none';

        }
       
    }
 ,

},

 
};




</script>