<template >

    <Update-Password-page></Update-Password-page>
    
    </template>
    
    <script>
    import  UpdatePasswordPage from '../../components/Profile/UpdatePassword.vue';
    
    export default {
       name: "UpdatePassword",
        components: { 
            UpdatePasswordPage,
                        }, 
        };
    
    </script>