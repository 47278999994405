<template>
    
<div class="fancy-feature-seventeen position-relative   side-nav">
        <div class="container side-bar-container">
            <div class="row align-items-center book-appointment-txt">
                <div class="row side-bar-row container">
                     <div class="col-lg-6 col-md-5 col-sm-4 col-4 ">
                        <img v-if="! url==''"  class="circle"   v-bind:src="url">
                        <img v-if="userimage && url==''"  class="circle"   v-bind:src="userimage">
                        <img v-if="!userimage && url==''"  class="circle-img" src="/images/user_img.png">    
                       <img class=" update-img image" src="/images/circle-btn.png" v-on:click="openupload()">
                     </div>
                     <input type="file" id="imgupload" class="imagefile" v-on:change="onFileChange()" style="display:none"/> 
                    <div class="col-lg-6 col-md-6 col-sm-8 col-8 username-side">
                        <span v-if="username">{{ username }}</span> 
                        <span v-else>{{$t('User Name')}}</span> 
                        <p v-if="userphone" class="user-phone">{{userphone}}</p>
                    </div>
                </div>
             </div>

             <div class="row side-title ">
                <h6> {{$t('Account')}}</h6>
            </div>


         <router-link :to= "{ name: 'Account' ,params:{locale:this.$i18n.locale} }"    class="no-decoration-link" >
           
            <div class="row side-item" v-bind:class="{ activeItem: isActive[0]}" v-on:click="activate(0)">
                <div class="col-3">
                    <img class="side-icon" src="/images/user_icon.png">
                </div>
                <div class="col-9 side-li side-center">
                    {{$t('Account')}}
                </div>
            </div>
        </router-link>


        <router-link :to= "{ name: 'MyAppointments' ,params:{locale:this.$i18n.locale} }" class="no-decoration-link">
            <div class="row side-item"  v-bind:class="{ activeItem: isActive[1]}" v-on:click="activate(1)">
                <div class="col-3">
                    <img  class="side-icon" src="/images/app-icon.png">
                </div>
                <div class="col-9 side-li side-center">  
                  {{$t('My Appointment')}}
                </div>
            </div>
         </router-link>


        <router-link :to="{ name: 'MyOrders' ,params:{locale:this.$i18n.locale} }" class="no-decoration-link">
            <div class="row side-item" v-bind:class="{ activeItem: isActive[2]}" v-on:click="activate(2)">
                <div class="col-3">
                    <img class="side-icon" src="/images/order-icon.png">
                </div>
                <div class="col-9 side-li side-center">
                      {{$t('My Orders')}}
                </div>
            </div>
        </router-link>


            <router-link :to="{ name: 'MyAdresses' ,params:{locale:this.$i18n.locale} }" class="no-decoration-link">
                <div class="row side-item" v-bind:class="{ activeItem: isActive[3]}" v-on:click="activate(3)">
                    <div class="col-3">
                        <img class="side-icon" src="/images/address-icon.png">
                    </div>
                    <div class="col-9 side-li side-center">
                        {{$t('My Addresses')}}
                    </div>
                </div>
         </router-link>  

          
         </div> 
    </div>
</template>


<script>
import axios from 'axios';


export default {
name: "SideBar",
mounted() {
this.getUserInfo();
this.getActiveItem();
this.getlocale();
},



 data() {
    return {
      username:'',
      userimage:'',
      userphone:'',
      locale:'ar',
      isActive:[],
      url:''
    };
  },



methods:{
    onFileChange(e) {
      const file =document.getElementById('imgupload').files[0];
      this.url = URL.createObjectURL(file);
    },
    openupload(){
        document.getElementById('imgupload').click();
    },

    activate(i){
        this.isActive[i]=true;
        localStorage.setItem('sidebarActive',i);
    },

    getActiveItem(){
       let i= localStorage.getItem('sidebarActive');
       this.isActive[i]=true;

    },

    getUserInfo()
		{	axios.get('profile',{
        headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
        }})
			.then((response)=>{
				
				this.username=response.data.content.name;
                this.userimage=response.data.content.userAvatar;
				this.userphone=response.data.content.phone;

			})
			.catch((error)=>{
				console.log(error)
			})
		},


getlocale()
{
    this.locale=localStorage.getItem('locale');
    if(this.locale=='ar')
    {

        let collection=	document.getElementsByClassName('side-li');
		for (let i = 0; i < collection.length; i++) {
           
 			 collection[i].style.textAlign  ='right'; 
		}
	
    }

}
},

	
	
}
    


 


</script>
<style>

</style>