<template>
      <div class="container about-us-cont home-padding">
            <div class="no-decoration-link" >  <h4> {{ $t('Contact Us') }}</h4></div>   
         </div>   
    <div class="container">
    <div class="row">

       
        <div class=" col-lg-9 col-md-7 offset-lg-1 col-12 contact-us-cont">
            <div class="row">
            <div class=" col-lg-6 col-md-12  col-12">
                <form id="email-form" @submit.prevent="(e) => sendEmail(e)">
                <div class="contact-form-container container">
                            <div class="contact-form form-padding">
                                <p class="contact-form">{{ $t('your-name') }} <span class="star">*</span></p>
                                <input type="text" name="name" v-model="name" class="form-input form-txt-input">
                            </div>

                            <div class="contact-form form-padding">
                                <p class="contact-form">{{ $t('your-email') }} <span class="star">*</span></p>
                                <input type="text" name="email" v-model="email" class="form-input form-txt-input">
                            </div>

                            <div class="contact-form form-padding">
                                <p class="contact-form">{{ $t('your-phone') }} <span class="star">*</span></p>
                                <input type="text" name="phone" v-model="phone" class="form-input form-txt-input">
                            </div>

                            <div class="contact-form form-padding">
                                    <p class="contact-form">{{ $t('your-message') }} <span class="star">*</span></p>
                                <textarea name="message" v-model="message" class="form-input form-txt-area"></textarea>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-8">
                                <div class="primary-btn form-submit">    
                                    <button type="submit" class="primary-btn-inside">{{ $t('send') }}</button>    
                                </div>
                                <div class="col-2"></div>
                                 </div>
                            </div>

                            </div>             
                </div>   
            </form>     
        </div>
        

        <div class=" col-lg-6 col-md-12  col-12 contact-btn-group">
          
                <div class="contact-form-container container">              
                   <div class="primary-btn form-submit">  
                    <!-- <a href="tel:+963996820471" class="no-decoration-call">   -->
                           <button  class="primary-btn-inside " v-on:click="phoneConnect()">
                                <div class="col-1"><img class="btn-icon" src="/images/call-icon.png"></div>
                                <div class="col-9">{{ $t('telephoneCall') }}</div>
                            </button> 
                        <!-- </a>    -->
                    </div>
               
                    <div class="primary-btn form-submit whatsapp-btn " v-on:click="whatsappConnect()">    
                           <button  class="primary-btn-inside whatsapp-btn-inside">
                                <div class="col-1"><img class="btn-icon"  src="/images/whats-app-btn-icon.png"></div>
                                <div class="col-9">{{ $t('connectWhatsapp') }}</div>
                            </button>    
                    </div>

                    <div class="primary-btn form-submit facebook-btn " v-on:click="facebookConnect()">    
                           <button  class="primary-btn-inside facebook-btn-inside">
                                <div class="col-1"><img class="btn-icon"  src="/images/facebook-icon-1.png"></div>
                                <div class="col-9">{{ $t('connect-with-facebook') }}</div>
                            </button>    
                    </div>

                    <div class="primary-btn form-submit instagram-btn " v-on:click="instaConnect()">    
                           <button  class="primary-btn-inside facebook-btn-inside">
                                <div class="col-1"><img class="btn-icon"  src="/images/instagram2-icon.png"></div>
                                <div class="col-9">{{ $t('connect-with-instagram') }}</div>
                            </button>    
                    </div>

                    <div class="primary-btn form-submit tiktok-btn " v-on:click="tiktokConnect()">    
                           <button  class="primary-btn-inside tiktok-btn-inside">
                                <div class="col-1"><img class="btn-icon"  src="/images/tiktok-icon.png"></div>
                                <div class="col-9">{{ $t('connect-with-tiktok') }}</div>
                            </button>    
                    </div>


                </div>                   
                </div>        
        </div>
</div>

          
    </div>
</div>
</template>


<script>

import { sendEmailVerification } from 'firebase/auth';
import SideBarSection from '../../components/Profile/SideBar.vue';
 import emailjs from 'emailjs-com';
 
export default {
name: "ContactUs",
data(){
    return{
    name:'',
    message:'',
    phone:'',
    email:'',
    }
},

mounted() {
    window.scrollTo(0, 0);
   
},
methods:{
    whatsappConnect(){
       // window.open('https://wa.me/message/ZE4LRX5224AOJ1') 
       window.open('https://wa.me/972593468000') ;
       
    },

    phoneConnect()
    {
        window.location.href='tel:+00972593468000';  
    },

    facebookConnect(){
      //  window.open('https://www.facebook.com/PalDerma/?mibextid=ZbWKwL');
      window.open('https://www.facebook.com/PalDerma');
     
    },
    instaConnect(){
    
      window.open('https://instagram.com/palderma1');
     
    },
    tiktokConnect()
    {
        window.open('https://www.tiktok.com/@palderma');
    },

    sendEmail(e) 
   {
   
    let emailform=document.getElementById('email-form')
    emailjs.sendForm('service_id', 'template_id', e.target, 'public_key')
        .then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    }
 

},

      // Reset form field
      

components: { 
    SideBarSection ,    
   
 }, 
};

</script>
<style>
.no-decoration-call{
    text-decoration: none !important;
}
</style>