<template >

    <log-out-page></log-out-page>
    
    </template>
    
    <script>
    import  LogOutPage from '../../components/Pages/LogOut.vue';
    
    export default {
       name: "LogOut",
        components: { 
            LogOutPage,
                        }, 
        };
    
    </script>