<template >

    <doctor-book-page></doctor-book-page>
    
    </template>
    
    <script>
    import  DoctorBookPage from '../../components/Pages/DoctorBook.vue';
    
    export default {
       name: "DoctorBook",
        components: { 
            DoctorBookPage,
                        }, 
        };
    
    </script>