<template>
    <div class="fancy-feature-seventeen position-relative home-padding" data-aos="fade-in" >
           <div class="container book-appointment-container">
               <div class="row align-items-center book-appointment-txt">
                   <div class="col-xl-6 col-lg-5" >
                       <!-- <img class="book-appointment-banner" src="/images/booking-nurse.svg"> -->
                       <div class="friend-background">
                           <div class="friend-info">
                               <img  v-bind:src="friend.paldermaFrindeImage" class="friend_circle_img" >
                               <div class="card-content">
                                       <h4 class="name no-decoration-link" style="text-decoration: none !important;"> {{friend.paldermaFrindeName}}</h4>  
                                       <p class="spec">{{friend.specialization}}</p> 
                                </div>
                               <p></p>
                           </div>
                       </div> 
                   </div> <!-- /.title-style-three -->
                 
                <div class="col-xl-6 col-lg-7 friend-container" >
                    
                    <div class="title-style-three   book-doctor-row">
                        <div v-if="lang=='en'" class="month-year ">
                            <h5>
                            <button @click="previousmonth()" class="month-pre"> <img class="arrows-icon" src="/images/chevron.png"> </button>
                           <span class="month-year-input">{{monthname}} - {{startyear}}</span> 
                            <button @click="nextmonth()" class="month-next"><img class="arrows-icon" src="/images/right.png"> </button>
                        </h5>   
                        </div>

                        <div v-else class="month-year ">
                            <h5>
                             <button @click="nextmonth()" class="month-next"><img class="arrows-icon" src="/images/right.png"> </button>
                                  <span class="month-year-input">{{monthname}} - {{startyear}}</span> 
                            <button @click="previousmonth()" class="month-pre"> <img class="arrows-icon" src="/images/chevron.png"> </button>
                        </h5>   
                        </div>

                        <div class="ltr-element">
                        <swiper  :modules="modules" :slides-per-view="6" @swiper="onSwiper"
                            :space-between="10" class="mySwiper">
                            <swiper-slide v-for="(dname ,index) in dayname" :key="index"  >
                                <div class="slide-content">
                                    <div class="slide-wrapper">      
                                        <!--- (click)="changeday(i)"-  -->
                                        <button  v-bind:class="{ activedaybtn: isActive[index], disabledSlide:(((startyear==nowyear)&&(nowmonth==startmonth)&&(index<nowday))) }" round color="light" class="scroll-click" v-if="(index !==0)"   v-on:click="changeday(index)">
                                                <ion-label class="rest-id" selected>{{index}} <br>{{dname}}</ion-label>
                                        </button>
                                        <!-- &&!((startyear==nowyear)&&(nowmonth==startmonth)&&(startday<nowday)) -->
                                    </div>
                                </div>   
                            </swiper-slide>
                        </swiper>
                      </div>
                   </div>

                  

                    <div class="title-style-three  text-lg book-doctor-row">
                        <h4>{{ $t('notes') }}</h4>
                        <textarea required class="note book-input-text form-control"></textarea>
                    </div>
                    <div id="response"></div>
                    <button v-if="isAuth" v-on:click="PostAppointment()" class="primary-btn-no-border border-radius-btn no-decoration">        
                           {{ $t('confirm-appointment') }}         
                    </button>
                    <router-link :to="{ name: 'SignIn' ,params:{locale:this.$i18n.locale}}" class="no-decoration"  v-else>
                            <button   class="primary-btn-no-border border-radius-btn no-decoration" >        
                                {{ $t('confirm-appointment') }}        
                            </button>
                   </router-link>
               </div>
           </div>
              </div>
           </div> <!-- /.container -->    
   </template>
      
      
      <script>
      import { logicalExpression } from '@babel/types';
      import axios from 'axios';
      import { useRouter } from 'vue-router';
      import { Swiper, SwiperSlide } from 'swiper/vue';
      import { useRoute } from 'vue-router'

  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/navigation';  
  // import required modules
  import { Navigation } from 'swiper';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },

    data() {
    return {
    startyear:'',
    startmonth:'',
    startday:'',
    monthname:'',
    numberofdays:'',
    calcmonth:'',
    dayname:[],
    calcdatemonth:'',
    myDate:'',
    friendid:'',
    PaldermaFriends:[],
    friend:[],
    isActive:[],
    nowyear:'',
    nowmonth:'',
    isAuth:false,
    nowday:'',
    swiper: null,
    lang:''
	 
    };
  },

    setup() {
       
      return {
        modules: [Navigation],
      };
    },
    mounted() {
        window.scrollTo(0, 0);
        this.getlocale();
        this.isLoogedin();
         this.getFirstDay();
         this.getdays();
         const route = useRoute();
         this.friendid = route.params.id;
         this.get_friend_info();
        
    },
      
    methods:{
        getlocale()
        {
            this.lang=localStorage.getItem('locale');
        },

        onSwiper(swiper) {
      this.swiper = swiper;
    },
        isLoogedin(){
        let token=localStorage.getItem('token');
        if(token!=null)
        {
            this.isAuth=true;
            this.username=localStorage.getItem('username');
        }
        
        },
        get_friend_info()
       {
            this.PaldermaFriends= JSON.parse(localStorage.getItem("paldermaFriends"));   
            this.friend=this.PaldermaFriends.find(fr =>fr.id==this.friendid);
            console.log('friend'+this.friend);   
       },

    getFirstDay()
        {
            const start = Date.now();
            const nowday=new Date(start);
            this.startyear = nowday.getFullYear();
            this.startmonth = nowday.getMonth()+1;
            this.startday=nowday.getDate();
            this.nowmonth=this.startmonth;
            this.nowyear=this.startyear;
            this.nowday= this.startday;
            this.isActive[this.startday]=true;
            this.swiper.slideTo(this.startday);
            this.getmonthname();

        },

        getmonthname()
        {
            switch(this.startmonth)
            {
                case(1): this.monthname='January';break;
                case(2): this.monthname='February'; break;
                case(3): this.monthname='March';break;
                case(4): this.monthname='April';break;
                case(5): this.monthname='May';break;
                case(6): this.monthname='June';break;
                case(7): this.monthname='July';break;
                case(8): this.monthname='August';break;
                case(9): this.monthname='September';break;
                case(10): this.monthname='October';break;
                case(11): this.monthname='November';break;
                case(12): this.monthname='December';break;
            }
        },

        previousmonth()
        {
            if(this.nowyear <= this.startyear && this.nowmonth < this.startmonth)
        { 
              this.startmonth--;
                if(this.startmonth==0)
                {
                    this.startmonth=12;
                    this.startyear--;
                }}
                if(this.startmonth!==this.nowmonth)
                this.swiper.slideTo(1);
                else
              this.swiper.slideTo(this.startday);
            this.getmonthname();
            this.getdays();

        },

        nextmonth(){
            this.startmonth++;
            if(this.startmonth==13)
            {
                this.startmonth=1;
                this.startyear++;
            }
            if(this.startmonth!==this.nowmonth)
            this.swiper.slideTo(1);
             else
             this.swiper.slideTo(this.startday);
            this.getmonthname();
            this.getdays();
        },

    getdays(){
        this.dayname=[];
        this.calcdatemonth=this.startmonth-1;
        this.numberofdays=30;

        if((this.calcdatemonth===3) || (this.calcdatemonth===5) || (this.calcdatemonth===8) || (this.calcdatemonth===10) )
        {
            this.numberofdays=30;
        }

        else  if((this.calcdatemonth===0) || (this.calcdatemonth===2) || (this.calcdatemonth===4) || (this.calcdatemonth===6) || (this.calcdatemonth===7) || (this.calcdatemonth===9) || (this.calcdatemonth===11) )
        {
            this.numberofdays=31;
        }
        else if(this.calcdatemonth===1)
       {
            if(this.startyear %4==0)
            {
                this.numberofdays=29;
            }
            else
                this.numberofdays=28;
       }
        this.getmonthname();

        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        for(let i=1 ;i<=this.numberofdays; i++)
        {
            const d = new Date( this.startyear,this.calcdatemonth,i);

            this.dayname[i] = days[d.getDay()];

        }


    }
,
    changeday(i)
    {
        this.startday=i;
        this.isActive=[];
        this.isActive[i]=true;
    },

     
    PostAppointment(){  
            //  const router=useRouter();
            const date=this.startyear+'/'+this.startmonth+'/'+this.startday;
            let note=document.getElementsByClassName('note')[0].value;
            if(!note)
            {
                document.getElementById("response").innerHTML = "Enter Your Notes";
	             document.getElementById('response').style.background="#edb2ad";
                 return 1;
            }
           

			const formData = new FormData();
            formData.append('doctor_id', this.friendid);
            formData.append('date', date);
            formData.append('note', note.toString());
		    axios.post('consulting',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
				}
			})
			.then(( response ) => {
                 //  route.push('/my-appointments');
			        console.log(response);
                    document.getElementById("response").innerHTML = "register has been done !";
	             document.getElementById('response').style.background="#e6ccff";
			    
                  setTimeout(() => {
                    document.getElementById("response").innerHTML ="";
                }, 5000);
                    //window.location.href='/my-appointments/';
                    
			} )
			.catch((error)=>{
			     console.log(error)
                 if(lang=='en')
                document.getElementById("response").innerHTML = "Appointment is not available, Please choose another time ";
                else
                 document.getElementById("response").innerHTML = "الموعد غير متاح في هذا التوقيت ، الرجاء اختيار توقيت أخر";
	             document.getElementById('response').style.background="#edb2ad";
			    
                  setTimeout(() => {
                    document.getElementById("response").innerHTML ="";
                }, 5000);
				})

			},

			 

    },


   
   
	
	  
    

    
       }
</script>