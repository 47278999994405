<template >

    <my-addresses-page></my-addresses-page>
    
    </template>
    
    <script>
    import  MyAddressesPage from '../../components/Profile/MyAddresses.vue';
    
    export default {
       name: "MyAddresses",
        components: { 
            MyAddressesPage,
                        }, 
        };
    
    </script>