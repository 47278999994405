<template >

    <Shipping-Cart-page></Shipping-Cart-page>
    
    </template>
    
    <script>
    import  ShippingCartPage from '../../components/Profile/ShippingCart.vue';
    
    export default {
       name: "ShippingCart",
        components: { 
            ShippingCartPage,
                        }, 
        };
    
    </script>