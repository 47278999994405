<template>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css">

    <div class="container home-padding">
    <div class="row">

        <div class="col-lg-3 col-md-5 col-sm-6 side-bar-border">
            <side-bar-section> </side-bar-section>
        </div>
        <div class="col-lg-9 col-md-7 col-sm-6">

      <form>
        <div class="row">
        <div class="col-lg-6 col-md-8 col-sm-6 container ">
            <div class="contact-form">
                <div id="response"></div>
                    <p class="contact-form">{{$t('Phone number')}} </p>
                     <!-- <input style="direction: ltr;" type="text" v-bind:value="userphone" class="form-input form-txt-input phone"> -->
                     <span class="ltr-element">
                     <MazPhoneNumberInput 
										default-country-code="PS"
										v-model="userphone"
										
										>
					</MazPhoneNumberInput>
                </span>			
                    </div>
        </div>
        </div>

    <div class="row ">
        <div class="col-lg-6 col-md-8 col-sm-6 container  ">
            <div class="contact-form">
                    <p class="contact-form">{{$t('Password')}}</p>
                    <input type="password" v-bind:value="password" required="" class="form-input form-txt-input password"  id="id_password">
                    <!-- <input type="password" name="password" class="form-input form-txt-input password" autocomplete="current-password"  id="id_password"> -->
                    <i class="far fa-eye" id="togglePassword" style=""></i>
                </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-8 col-sm-6 container ">
            <div class="contact-form">
                    <p class="contact-form" >{{ $t('confirm-password') }}</p>
                     <input type="password" v-bind:value="password_confirmation" class="form-input form-txt-input password_confirmation">
                 </div>
        </div>
             
    </div>


    <div class="row">
       
        <div class="col-lg-5 col-md-8 col-sm-6 container ">
            
            <div class="row margin-btn " >
				<button class="col-lg-5 col-md-6 col-11 female-btn " v-on:click="updatePassword()"  >{{ $t('Change Password') }}</button>
														
			</div>
            </div> 
    </div>
</form>
</div>
   </div> 
</div>
   
</template>


<script>

import SideBarSection from '../../components/Profile/SideBar.vue';
import axios from 'axios';
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

export default {
name: "Account",
components: { 
    SideBarSection ,    
    MazPhoneNumberInput
 }, 
 css: [
    'maz-ui/css/main.css',
  ],

  build: {
    transpile: ['maz-ui'], 
  },

 

 mounted() {
window.scrollTo(0, 0);
const togglePassword = document.querySelector('#togglePassword');
  const password = document.querySelector('#id_password');

  togglePassword.addEventListener('click', function (e) {
    // toggle the type attribute
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
    // toggle the eye slash icon
    this.classList.toggle('fa-eye-slash');
});

},
setup(){
    localStorage.setItem('sidebarActive','0');

},

 data() {
    return {
      username:'',
      userimage:'',
      userphone:'',
      email:'',
      age:'',
      gender:'',
      isAuth:false
    };
  },



methods:{
    updatePassword(){
            let phone=this.userphone;
            let password=document.getElementsByClassName('password')[0].value;
            let password_confirmation=document.getElementsByClassName('password_confirmation')[0].value;
			const formData = new FormData();
            formData.append('phone', phone);
            formData.append('password', password);
            formData.append('password_confirmation', password_confirmation);
           
		    axios.post('auth/reset',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
				}
			})
			.then(( response ) => {
              
                console.log(response);
                document.getElementById("response").innerHTML = "تمت عملية التعديل بنجاح";
	            document.getElementById('response').style.background="#7ed996";
                document.getElementById('response').style.color="#c6ccc7";
			    
                  setTimeout(() => {
                    document.getElementById("response").innerHTML ="";
                }, 5000);
			} )
			.catch((error)=>{
                alert(23);
                console.log(error)
                document.getElementById("response").innerHTML = " حدثت مشكلة أثناء التعديل ، يرجى إعادة المحاولة";
	            document.getElementById('response').style.background="#edb2ad";
			    
                  setTimeout(() => {
                    document.getElementById("response").innerHTML ="";
                }, 5000);
                
                 
				})
               
			},

			 
   

   
  
},

   

};
    



</script>
<style>
#togglePassword{
    margin-right: -30px; 
    margin-top: 15px;
    position:absolute;
    z-index: 2;
    cursor: pointer;
}
</style>