<template>
    <div class="container home-padding">
    <div class="row">

        <div class="col-lg-3 col-md-5 col-sm-12 col-12 side-bar-border">
            <side-bar-section> </side-bar-section>

        </div>
        <div class=" col-lg-9 col-md-7  col-12 ">
           <div class="row container addresses-cont">
                 <h6>{{ $t('address') }}</h6>
             </div> 
            <div class="row">
            <div class=" col-lg-6 col-md-12  col-12 side-bar-gray-no-margin " >
                <div class=" container">
                             <div class="contact-form" v-for="address in addresses" :key="address.id">
                                <div class="contact-form">
                                    <div  class="form-input form-txt-input address-div">
                                      <div class="row">
                                        <div class="col-10">
                                            <span class="secondary-text">{{ address.city }} , {{ address.street }}</span>
                                         </div>
                                         <div class="col-2">
                                            <span class="secondary-text"> <img class="star" src="/images/star-filled.png"></span>
                                         </div>
                                    </div>
                                </div>
                            </div>
                           

                          

                            </div>
                            <p class="secondary-text add-address-btn">
                                <span v-on:click="show_new_address()">
                                 + {{ $t('addNewAddress') }}
                              </span> 
                          </p>          
                      </div>        
                  </div>
        

        <div class=" col-lg-6 col-md-12  col-12" v-if="show==1" >
             
            <div class="row container" >
                 <h6> {{ $t('addNewAddress') }} </h6>
             </div> 
             <form action="#" @submit.prevent="submit">
         <div class="row">
            <div class=" col-lg-12 col-md-12  col-12">

                <div class=" container">
                                <div class="address-form">
                                        <p class="secondary-text">{{ $t('city') }}</p>
                                        <select name="city" class="form-input form-txt-input">
                                            <option value="Safad" >{{ $t('safad') }}</option>
                                            <option value="Acre">{{ $t('acre') }}</option>
                                            <option value="Haifa">{{ $t('haifa') }}</option>
                                            <option value="Tiberias">{{ $t('tiberias') }}</option>
                                            <option value="Nazara">{{ $t('nazara') }}</option>
                                            <option value="Beisan">{{ $t('beisan') }}</option>
                                            <option value="Jenin">{{ $t('jenin') }}</option>
                                            <option value="Hadera">{{ $t('hadera') }}</option>
                                            <option value="Latania">{{ $t('latania') }}</option>
                                            <option value="Tulkarm">{{ $t('tulkarm') }}</option>
                                            <option value="Nablus">{{ $t('nablus') }}</option>
                                            <option value="Gaza">{{ $t('gaza') }}</option>
                                            <option value="Rafah">{{ $t('rafah') }}</option>
                                            <option value="Lydda">{{ $t('lydda') }}</option>
                                            <option value="Jericho">{{ $t('jericho') }}</option>
                                            <option value="Tel Aviv">{{ $t('tel-aviv') }}</option>
                                            <option value="Ramleh">{{ $t('ramleh') }}</option>
                                            <option value="Yafa">{{ $t('yafa') }}</option>
                                            <option value="Jerusalem">{{ $t('jerusalem') }}</option>
                                            <option value="Ashdod">{{ $t('ashdod') }}</option>
                                            <option value="Ashkelton">{{ $t('ashkelton') }}</option>
                                            <option value="Beersheba">{{ $t('beersheba') }}</option>
                                            <option value="Hebron">{{ $t('hebron') }}</option>
                                            <option value="Bethlehem">{{ $t('bethlehem') }}</option>
                                            <option value="Ramallah">{{ $t('ramallah') }}</option>                         
                                            <option value="Khan Younes">{{ $t('khan-younes') }}</option>
                                        </select>    
                                 </div>

                                 <div class="address-form">
                                        <p class="secondary-text">{{ $t('street') }}</p>
                                         <input name="street" type="text" class="form-input form-txt-input">     
                                 </div>

                                 <div class="address-form">
                                        <p class="secondary-text">{{ $t('fullAddress') }}  </p>
                                         <input name="full_address" type="text" class="form-input form-txt-input">     
                                 </div>

                                 <div class="primary-btn form-submit">    
                                    <button type="submit" class="primary-btn-inside">
                                        {{ $t('addAddress') }}
                                    </button>    
                                </div>

                           </div>
                 </div>
          
            </div>
        </form>
     </div>        
   </div>
</div>

            <!-- <div class="col-lg-4 col-md-3 col-sm-12">
            <div class="col-md-12 col-lg-6 col-12">
                <div class="contact-form-container container">
                            <div class="contact-form">
                                <p class="contact-form">your name</p>
                                <input type="text" class="form-input form-txt-input">
                            </div>

                            <div class="contact-form">
                                    <p class="contact-form">your message</p>
                                <textarea class="form-input form-txt-area"></textarea>
                                
                                <div class="primary-btn form-submit">    
                                    <button type="submit" class="primary-btn-inside">Send</button>    
                                </div>


                            </div>             
                </div>    
            </div> 
     -->
   <!-- </div> -->
    </div>
</div>
</template>


<script>

import SideBarSection from '../../components/Profile/SideBar.vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
name: "MyAddresses",
mounted() {
    window.scrollTo(0, 0);

    this.getAddresses();

},

components: { 
    SideBarSection ,    
   
 }, 

 data() {
    return {
        show:0,
        addresses:[]
	 
    };
  },

  setup(){

    
    localStorage.setItem('sidebarActive','3');
        const router=useRouter();
        const submit=async e =>{
		e.preventDefault()
		const form =new FormData(e.target);
		const inputs=Object.fromEntries(form.entries());
		const response=await axios.post('address',inputs,{headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
            }}
			).then((response)=>{	
              location.reload();
			
		})
		
	  .catch((error)=>{
	
	 })	;	
		
	  }
	
	  return {
		submit
	  }
    },

 methods:{
    show_new_address(){
       this.show=1;
      
    },

    getAddresses()
		{	axios.get('address',{
        headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
        }})
			.then((response)=>{
				
				this.addresses=response.data.content.address;
				console.log("addresses"+this.addresses);
				

			})
			.catch((error)=>{
				console.log(error)
			})
		},

       
		

 }
}


</script>