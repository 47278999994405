<template >

    <about-us-page></about-us-page>
    
    </template>
    
    <script>
    import  AboutUsPage from '../../components/Profile/AboutUs.vue';
    
    export default {
       name: "AboutUs",
        components: { 
            AboutUsPage,
                        }, 
        };
    
    </script>