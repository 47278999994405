<template>
    <div class="container home-padding">
    <div class="row">

        <div class="col-lg-3 col-md-5 col-sm-12 col-12 side-bar-border">
            <side-bar-section> </side-bar-section>

        </div>
        <div class="col-lg-9 col-md-7 col-sm-12 col-12">
            <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 container privacy side-bar-gray">
            <h6>{{$t('My Orders')}}</h6>
            <div class="my-orders">
                <div class="order" v-for=" (order,index) in orders" :kye="order.id" v-on:click="getOrderDetails(order.id)">
                    <div class="order-status">
                        <div class="container">
                        <div class="row ">
                            <div class="col-8 ">
                               <div class="order-id">{{ $t('orderId') }}</div> 
                               <div class="order-id"><strong>#{{order.id}}</strong></div> 
                            </div>
                            <div class="col-4">
                                <div v-if="order.status==0" class="order-pending">
                                    {{ $t('pending') }}
                                </div>

                                <div v-if="order.status==1" class="order-accepted">
                                    {{ $t('accepted') }}
                                </div>

                                <div v-if="order.status==2" class="order-completed">
                                    {{ $t('completed') }}
                                </div>
                            </div>
                        </div>
                     </div>
                 </div>
                 <div class="container order-address">
                        <div class="row ">
                            <div class="col-2">
                                <img src="/images/order-address.png">
                            </div>
                            <div class="col-10">
                                
                                  {{order.address.city}} , {{ order.address.street }} , {{ order.address.fullAddress }}
                                
                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-2">
                                <img src="/images/cal-vector.png">
                            </div>
                            <div class="col-7 ">
                                  {{order.date}}
                            </div>
                            <div class="col-3 ">
                                  <h6>{{order.total}} ILS</h6>
                            </div>
                        </div>
                </div>
            </div>
                </div>



        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12 container privacy order-details"  >
            <!--  -->
            <h6>{{ $t('orderDetails') }} </h6>
            <div class="order-details" v-for="OrderDetail in OrderDetails" :key="OrderDetail.id">
            <div class="row">
                <div class="col-1">
                    <img src="/images/order-address.png">
                </div>
                <div class="col-11">
                    <span class="privacy">deliver to: {{ OrderDetails.orderDetails.address.city }} , {{ OrderDetails.orderDetails.address.street }} , {{ OrderDetails.orderDetails.address.fullAddress }}</span>

                </div>
            </div>
            <div class="my-orders">
                <div class="my-orders">
                <div class="order">
                    <div class="order-status">
                        <div class="container">
                        <div class="row ">
                            <div class="col-3 ">
                               <div class="order-id">Order ID</div> 
                               <div class="order-id"><strong>#{{ OrderDetails.orderDetails.id }}</strong></div> 
                            </div>

                            <div class="col-1 ">
                               <img src="/images/cal-vector.png">
                            </div>
                            <div class="col-4 ">
                                {{ OrderDetails.orderDetails.date }}
                            </div>

                            <div class="col-4">
                                <div v-if="OrderDetails.orderDetails.status==0" class="pending">
                                    Pending
                                </div>

                                <div v-if="OrderDetails.orderDetails.status==1" class="accepted">
                                    Accepted
                                </div>

                                <div  v-if="OrderDetails.orderDetails.status==2" class="completed">
                                    Completed
                                </div>
                            </div>
                        </div>
                     </div>
                 </div>
                 <div class="container order-address " v-for=" product in OrderDetails.orderDetails.details" >
                        <!--  order row-->
                        <div class="row order-status"> 
                            <div class="col-lg-4 col-md-4 col-4">
                                <img class="order-product-img" v-bind:src="product.product.productImage">
                            </div>

                            <div class="col-lg-4 col-md-3 col-3">
                               <h6 class="prod-name">{{product.product.productName}}</h6>
                               <h6 class="prod-count">{{product.quantities}} Pcs</h6>
                            </div>

                            <div class="col-lg-4 col-md-3 col-3">
                               <h6 >{{product.product.price}} ILS</h6>
                               
                               
                            </div>
                        </div>


                        <!-- <div class="row order-status"> 
                            <div class="col-lg-4 col-md-4 col-4">
                                <img class="order-product-img" src="/images/prod.png">
                            </div>

                            <div class="col-lg-4 col-md-3 col-3">
                               <h6 class="prod-name">OBH Combi</h6>
                               <span >75ml</span>
                               <h6 class="prod-count">10 Pcs</h6>
                            </div>

                            <div class="col-lg-4 col-md-3 col-3">
                               <h6 >2000</h6>
                               
                               
                            </div>
                        </div> -->


                       

                       
                </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
    
</div>
</div>
</template>


<script>

import SideBarSection from '../../components/Profile/SideBar.vue';
import axios from 'axios';

export default {
name: "MyAddresses",
mounted() {
    window.scrollTo(0, 0);

},

components: { 
    SideBarSection ,    
   
 }, 

 data() {
    return {
        orders:[],
        OrderDetails:[],
        orderproducts:[],
    };
  },

mounted(){
this.getOrders();
this.reloadpage();
},


setup(){
    localStorage.setItem('sidebarActive','2');

},

methods:{

    reloadpage(){
      let reload=  localStorage.getItem('reload-orders');
        if(reload==1)
        {
            localStorage.setItem('reload-orders',0);
            location.reload();

        }
    },


    getOrderDetails(orderid){
    {	axios.get('order/'+orderid,{
        headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
           
        }})
			.then((response)=>{
				
				this.OrderDetails=response.data.content;
				console.log("orderDetails"+this.OrderDetails);
				

			})
			.catch((error)=>{
				console.log(error)
			})
        }		
},


 getOrders()
		{	axios.get('order',{
        headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
        }})
			.then((response)=>{
				
				this.orders=response.data.content.order;
				console.log("addresses"+this.orders);
				

			})
			.catch((error)=>{
				console.log(error)
			})
		},
       
},

 
};

</script>