<template >

    <Privacy-page></Privacy-page>
    
    </template>
    
    <script>
    import  PrivacyPage from '../../components/Profile/Privacy.vue';
    
    export default {
       name: "Privacy",
        components: { 
            PrivacyPage,
        }, 
    };
    
    </script>