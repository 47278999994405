<template>
    <!-- Messenger Chat plugin Code -->
	<div class="messenger-chat">
		<div id="fb-root"></div>

		<!-- Your Chat plugin code -->
		<div id="fb-customer-chat" class="fb-customerchat"></div>
</div>
<div class="footer-style-four theme-basic-footer">
				<div class="container">
					<div class="inner-wrapper">
						<div class="row">
							<div class="col-lg-4 footer-intro mb-20">
                                <img src="/images/icon-white.svg">
								<div class="logo"><a href="index.html"></a></div>
								<p > {{ $t('about-us') }}</p>
								
                                <p class="copyright   pb-5">{{ $t('palderma-center-2023') }} </p>

							</div>
							<div class="col-lg-2 col-sm-4 ms-auto mb-30">
								<h5 class="footer-title">{{ $t('brands') }}   </h5>
								<ul class="footer-nav-link style-none-footer" id="style-none-footer">
									<li v-on:click="scrollToTop()" v-for="(productcat,index ) in productsCategory" :key="productcat.id" > <router-link :to="{ name: 'Products' }" role="button" v-if="index<4">{{productcat.categoryName}}</router-link></li>
									
								</ul>
							</div>
							<div class="col-lg-3 col-sm-4 mb-30">
								<h5 class="footer-title">{{ $t('services') }} </h5>
								<ul class="footer-nav-link style-none-footer" id="style-none-footer">
									<li v-on:click="scrollToTop()"  v-for="(servicecat,index ) in servicesCategory" :key="servicecat.id" > <router-link :to="{ name: 'SubServices' ,params:{locale:this.$i18n.locale, id:servicecat.id}}"  v-if="index<4" role="button" >{{servicecat.categoryName}}</router-link></li>
									
								</ul>
							</div>
							<div class="col-xl-2 col-lg-3 col-sm-4 mb-30">
								<h5 class="footer-title">{{ $t('support') }} </h5>
								<ul class="footer-nav-link style-none-footer" id="style-none-footer">
									<li v-on:click="scrollToTop()"> <router-link :to="{ name: 'Privacy' }" role="button">{{ $t('privacy-policy') }}</router-link></li>
									<li v-on:click="scrollToTop()"> <router-link  :to="{ name: 'ContactUs' }" role="button">{{ $t('Contact Us') }}</router-link></li>

								</ul>
							</div>
						</div>
						
					</div> <!-- /.inner-wrapper -->
				</div>
			</div> <!-- /.footer-style-four --></template>

			<script>
		 import { logicalExpression } from '@babel/types';
    import axios from 'axios';
    import { useRouter } from 'vue-router';



export default {
  components: {
   
  },
		data() {
  return {
  productsCategory:[],
  servicesCategory:[]  ,
  lang:''
  
  };
},

  setup() {
     
  },
  mounted() {
    this.getProductCategories();
	this.getlocale();
	this.chatOpen();
  },
    
  methods:{
	chatOpen(){
	   var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", 430571683658464);
      chatbox.setAttribute("attribution", "biz_inbox");
	  window.fbAsyncInit = function() {
        FB.init({
          autoLogAppEvents : true,
          xfbml            : true,
          version          : 'v16.0'
        });
      };

       window.fbAsyncInit = function() {
        FB.init({
          xfbml            : true,
          version          : 'v16.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/ar_AR/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
},


//   initFacebookSdk() {
//     return new Promise(resolve => {
//         // wait for facebook sdk to initialize before starting the vue app
//         window.fbAsyncInit = function () {
//             FB.init({
//                 appId: '990832845618447',
//                 cookie: true,
//                 xfbml: true,
//                 version: 'v8.0'
//             });

//             // auto authenticate with the api if already logged in with facebook
//             FB.getLoginStatus(({ authResponse }) => {
//                 if (authResponse) {
//                     accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
//                 } else {
//                     resolve();
//                 }
//             });
//         };

//         // load facebook sdk script
//         (function (d, s, id) {
//             var js, fjs = d.getElementsByTagName(s)[0];
//             if (d.getElementById(id)) { return; }
//             js = d.createElement(s); js.id = id;
//             js.src = "https://connect.facebook.net/en_US/sdk.js";
//             fjs.parentNode.insertBefore(js, fjs);
//         }(document, 'script', 'facebook-jssdk'));    
//     });
//   },
scrollToTop()
	{
		window.scrollTo(0, 0);
		localStorage.setItem('footer_reload',1);

	},
    getProductCategories()
  {
     this.productsCategory= JSON.parse(localStorage.getItem("productCategory"));
	 this.servicesCategory= JSON.parse(localStorage.getItem("serviceCategory"));


  },
  getlocale()
  {
	this.lang=localStorage.getItem('locale');
	if (this.lang=='ar')
	{
	let collection=	document.getElementsByClassName('style-none-footer');
		for (let i = 0; i < collection.length; i++) {
 			 collection[i].style.paddingRight =0; 
		}
	}
  }
	}
	}	</script>