<template >

    <Sub-Services-page></Sub-Services-page>
    
    </template>
    
    <script>
    import  SubServicesPage from '../../components/Pages/SubServices.vue';
    
    export default {
       name: "Services",
        components: { 
            SubServicesPage,
                        }, 
        };
    
    </script>