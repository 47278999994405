<template >

    <Palderma-Friend-page></Palderma-Friend-page>
    
    </template>
    
    <script>
    import  PaldermaFriendPage from '../../components/Pages/PaldermaFriend.vue';
    
    export default {
       name: "PaldermaFriend",
        components: { 
            PaldermaFriendPage,
                        }, 
        };
    
    </script>