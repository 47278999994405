<template>
 <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css">

    <div class="row home-padding-signup" >
		<div class="col-sm-12">
				
					<div class="inner-wrapper">
						<div class="row">	
							<div class="col-lg-5 col-md-12 col-sm-8  padding-sign-up ">
								<img class="img-signin" src="/images/nurse5.svg">							
							</div>
							<div class="col-lg-7 col-md-12 col-sm-4  hello">
								<!-- <h3>{{ $t('hello-0') }}</h3> -->
								<h3>{{ $t('welcome-back') }}</h3>
							
								
							
								<div class="col-sm-10 ">
								<form action="#" @submit.prevent="submit">
									<div id="response"></div>
									<label class="form-group">{{ $t('phone-number') }}</label>
									<div class="form-group  ltr-element">
										
										
										<MazPhoneNumberInput 
										default-country-code="PS"
										v-model="phone"
										
										>
										</MazPhoneNumberInput>
									
									</div>
									<br>
									<div class="form-group">
										<label class="form-group">{{ $t('password') }}</label>
										<div class="row ">
										<div class="col-lg-12 col-md-12 col-sm-12 container  ">
											<div class="contact-form">
                  
											<input type="password"  name="password"  class="form-input form-txt-input password"  id="id_password">
											<!-- <input type="password" name="password" class="form-input form-txt-input password" autocomplete="current-password"  id="id_password"> -->
											<i class="far fa-eye" id="togglePasswords" style=""></i>
											</div>
										</div>
									</div>
									</div>
									<div>
										<label class="create-account">{{ $t('dont-have-an-account') }}<strong> <router-link class="no-decoration-link brown" :to="{ name: 'SignUp',params:{locale:this.$i18n.locale} }" role="button"> {{ $t('create-account') }}</router-link>    </strong></label>
									</div>
									<div class="row">
										<input name="phone" hidden  v-bind:value="phone">
										<div class="col-6 ">
											<div class="primary-btn">    
												<button type="submit" class="primary-btn-inside">{{ $t('Login') }}</button>    
      							 		   </div>
										</div>
									</div>
									
									

								</form>
							</div>
							</div>
						</div>
						
					 <!-- /.inner-wrapper -->
				</div>
         </div>
        </div>

       
</template>


<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
import { useRouter } from 'vue-router';
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

export default {
	css: [
    'maz-ui/css/main.css',
  ],

  build: {
    transpile: ['maz-ui'], 
  },

components: { MazPhoneNumberInput },
name: 'SignIn',
data() {
    return {
	message:'',
	phone:'',
	 
	 
    };
  },

  mounted(){
	window.scrollTo(0, 0);
	const togglePassword = document.querySelector('#togglePasswords');
 	 const password = document.querySelector('#id_password');
	 this.getlocale();
 	 togglePassword.addEventListener('click', function (e) {
 	   // toggle the type attribute
     const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
     password.setAttribute('type', type);
     // toggle the eye slash icon
     this.classList.toggle('fa-eye-slash');
});

  },
     setup(){

        const router=useRouter();
        const submit=async e =>{
		e.preventDefault()
		const form =new FormData(e.target);
		const inputs=Object.fromEntries(form.entries());
		const response=await axios.post('auth/login',inputs).then((response)=>{	
			localStorage.setItem('token',response.data.content.accessToken);
			localStorage.setItem('username',response.data.content.name);
			let products=JSON.stringify("");
		
			localStorage.setItem('products',products);
		//	localStorage.setItem('quantities',quantities);
		//	axios.defaults.headers.common['Authorization'] =  'Bearer ' + response.content.accessToken;
			
			localStorage.setItem('reloadLogin','1');
		
			router.push('/');
		})
		
	  .catch((error)=>{
	let lang=localStorage.getItem('locale')
	if(lang=='en')
	{
		document.getElementById('response').innerHTML="phone number or password is incorrect!";
	}
	else
	document.getElementById('response').innerHTML="رقم هاتف أو كلمة مرور خاطئين";
	document.getElementById('response').style.background="#edb2ad";
	setTimeout(() => {
                    document.getElementById("response").innerHTML ="";
                }, 5000);
	 })	;	
	//	console.log('Bearer ' + response.content.accessToken);
	  }
	
	  return {
		submit
	  }
    },

   methods:{
	getlocale(){
	  this.lang=localStorage.getItem('locale');
	  if(this.lang=='en')
	  {

       document.getElementById('togglePasswords').style.marginLeft='-40px';
	  }
	  else
	  document.getElementById('togglePasswords').style.marginRight='-40px';
	},
   }

};
</script>

<style>
label{
	padding-bottom: 1%;
	font-size: 12px;
}
</style>